<div class="strategy">
  <div class="title-container">
    <h5>{{strategy.title}}</h5>
    <hr>
  </div>
  <div class="sale-container">
    <div class="title-sale-container">
      <p class="label"> Vente de l'expert : </p>
      <p class="sub-label">{{strategy.percentSales}}%</p>
    </div>
    <div class="img-container">
      @if (strategy.percentSalesRounded!= null) {
      <img src="assets/images/pourcent_sales_{{strategy.percentSalesRounded}}.png" width="300" />
      }
    </div>

  </div>
  <div class="percentage-container">
    <p class="percentage-title">Pourcentage de Call</p>
    <div class="percentage-text-container">
      <p>{{strategy.percentCall}}%</p>
      <div class="percentage-img"></div>
    </div>
  </div>

  <div class="strategy-text-container">
    <div [innerHTML]="strategy.text| safeHtml" class="strategy-text"></div>
  </div>