import { Component, EventEmitter, Injectable, OnInit, Output } from '@angular/core';

@Component({
  selector: 'chart_footer',
  templateUrl: './chart_footer.component.html',
  styleUrls: ['./chart_footer.component.scss']
})

@Injectable()
export class ChartFooterComponent implements OnInit {
  @Output() eventClosePopup = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  ClosePopup() {
    this.eventClosePopup.emit();
  }
}
