import { Component, Inject, Injectable, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HeaderService } from 'src/app/services/header/header.component.service';

@Component({
  selector: 'myAccount',
  templateUrl: './my_account.component.html',
  styleUrls: ['./my_account.component.scss']
})

@Injectable()
export class MyAccountComponent implements OnInit {  

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private headerService : HeaderService) { }

  ngOnInit(): void {
    this.setHeaderTitle(); 
  }

  ngAfterViewInit() {    
  }
  
  setHeaderTitle() {
    this.headerService.setTitle("Mes informations"); 
  }
  

}
