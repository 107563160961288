import { Injectable } from '@angular/core';
import * as $ from 'jquery/dist/jquery.min.js';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})

export class NotificationService {
  constructor(private toastr: ToastrService) {
  }

  MAX_TOASTS:number = 3;
  duration:number = 5000;

  ShowSuccess(message: string | undefined, title: string | undefined) {
    this.LimitMaxToastr();
    this.toastr.success(message, title, { timeOut: this.duration, positionClass: 'toast-top-right' });    
  }

  ShowError(message: string | undefined, title: string | undefined) {
    this.LimitMaxToastr();
    this.toastr.error(message, title, { timeOut: this.duration, positionClass: 'toast-top-right' })    
  }

  ShowInfo(message: string | undefined, title: string | undefined) {
    this.LimitMaxToastr();
    this.toastr.info(message, title, { timeOut: this.duration, positionClass: 'toast-top-right' })    
  }

  ShowWarning(message: string | undefined, title: string | undefined) {
    this.LimitMaxToastr();
    this.toastr.warning(message, title, { timeOut: this.duration, positionClass: 'toast-top-right' })    
  }

  LimitMaxToastr() {
    var toasts = $("#toast-container > *:not([hidden])");

    if (toasts && toasts.length >= this.MAX_TOASTS) {
      for (var i = 0; i < toasts.length; i++) {
        let toast = toasts[i];
        toast.hidden = true;
      }
    }      
  }
}

