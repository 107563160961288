<div class="avis-container" id="divAvis">
  <div>
    <div class="avis-header-container">
      <img src="assets/images/header_avis.png" />
      <label >Avis et recommandation</label>
    </div>
    <div class="avis-content-container">
      <div class="avis-article-name">
        {{title}}
      </div>
      <div id="container" class="text-container textAvis" [innerHTML]="text"></div>
    </div>
    
  </div>
</div>
