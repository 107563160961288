import { Component, Injectable, OnInit } from '@angular/core';

@Component({
  selector: 'loginWithTeaser',
  templateUrl: './login_with_teaser.component.html',
  styleUrls: ['./login_with_teaser.component.scss']
})

@Injectable()
export class loginWithTeaserComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {
  
  }
}
