import { Component, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-corn',
  templateUrl: './corn.component.html',
  styleUrls: ['./corn.component.scss']
})
export class CornComponent implements OnInit {
  @Output() sentProductId: number = 2;

  classBlurred: any;

  ngOnInit(): void {
  }


}
