export class Configuration {
  readonly websiteBaseURL: string;
  readonly apiEndpoint: string;
  readonly agritelApiEndpoint: string;
  readonly analysisImagesUrl: string;
  readonly advisorsImagesUrl: string;
  readonly logUserExperience: boolean;
  readonly showToggleTheme: boolean;
  readonly environment: string;
  readonly forceHttps: boolean;

  readonly advisorInformationAgritelFarmerPlus: {
    titleAdvisor: string,
    nameAdvisor: string,
    phoneAdvisor: string,
    emailAdvisor: string,
  };
  readonly advisorInformationAgritelFarmer: {
    titleAdvisor: string,
    nameAdvisor: string,
    phoneAdvisor: string,
    emailAdvisor: string,
  }
} 
