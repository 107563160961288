import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/shared/components/notifications/notification.service';
import { AlertService } from 'src/app/services/http/alerts/alert.service';
import { AlertSubscriptionUser } from 'src/model/alerts/AlertSubscriptionUser';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-alert-notifications',
  templateUrl: './alert-notifications.component.html',
  styleUrls: ['./alert-notifications.component.scss']
})


export class AlertNotificationsComponent implements OnInit {

  constructor(private alertService: AlertService, private notificationService: NotificationService, private breakpointObserver: BreakpointObserver) { }

  allAlertSubscriptType: AlertSubscriptionUser[] = [];
  isHidden: boolean = false;
  hideIconDesktop: string = "block";
  toggleIcon: string = "keyboard_arrow_down";
  isDesktop: boolean = this.breakpointObserver.isMatched('(max-width: 768px)');



  ngOnInit(): void {
    this.getSubscriptionForUser();
    if (!this.isDesktop) {
      this.hideIconDesktop = "none";
    }
  }


  getSubscriptionForUser() {
    this.alertService.GetAllSubscriptionsAlertsForUser().subscribe({
      next: (item: AlertSubscriptionUser[]) => {
        this.allAlertSubscriptType = item;
      },
      error: (err: { status: number; }) => {
        if (err.status != 401) {
          this.notificationService.ShowError("Erreur", "Erreur lors des chargements de vos préferences")
        }
      },
      complete: () => {
        // console.info('Information loaded');
      }
    })
  }


  onSubmit() {
    this.alertService.SaveSubscriptionAlertForUser(this.allAlertSubscriptType).subscribe({
      error: (err: { status: number; }) => {
        if (err.status != 401) {
          this.notificationService.ShowError("Erreur", "Erreur lors de l'envoie de vos préferences")
        }
      },
      complete: () => {
        this.notificationService.ShowSuccess('Vos préférences ont bien été enregistrées', '')
      }
    })
  }

  closePanel() {
    const isSmallScreen = this.breakpointObserver.isMatched('(max-width: 768px)');
    if (isSmallScreen) {
      this.isHidden = !this.isHidden;
      // Based on the isHidden prop 
      if (this.isHidden) {
        this.toggleIcon = "keyboard_arrow_up";
      } else {
        this.toggleIcon = "keyboard_arrow_down";
      }
    }
    else {
      this.isHidden = false;
    }
  }
}