import { Component, Output } from '@angular/core';

@Component({
  selector: 'app-rape',
  templateUrl: './rape.component.html',
  styleUrls: ['./rape.component.scss']
})
// Colza 
export class RapeComponent {
  @Output() sentProductId: number = 3;
  classBlurred: any;

}
