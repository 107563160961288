import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class HeaderService {
  title: BehaviorSubject<string> = new BehaviorSubject('');
  setTitle(title: string) {
    this.title.next(title);
  }

}