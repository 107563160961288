import { Component, ComponentFactory, ComponentFactoryResolver, Injectable, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as $ from 'jquery/dist/jquery.min.js';
import { QuotesService } from '../../../services/http/quotes.service';
import { QuotesComponent } from '../quotes_component.component';
import { PhysicalQuotesLineComponent } from './physical_quotes_line/physical_quotes_line.component';

@Component({
  selector: 'physical_quotes',
  templateUrl: './physical_quotes.component.html',
  styleUrls: ['./physical_quotes.component.scss']
})

@Injectable()
export class PhysicalQuotesComponent extends QuotesComponent implements OnInit {
  animationSpeed = 5;

  constructor(public dialog: MatDialog, private quotesService: QuotesService, private resolver: ComponentFactoryResolver) {
    super();
  }

  @ViewChild('table_quotes_physical_body_Placeholder', { read: ViewContainerRef })
  public table_quotes_physical_body_Placeholder: ViewContainerRef;

  override ngOnInit(): void {
    this.tableTitle = "Physique";
    this.dateLastUpdate = new Date().toLocaleDateString("fr-FR");
    this.showDropDownNbLines = false;
    this.showRefreshButton = false;
    this.tableType = "Physical";
  }

  onImgMouseover($event): void {
    const box = document.getElementById('divTooltipPhysical');
    box.style.display = 'block';
  }

  onImgMouseout($event): void {
    const box = document.getElementById('divTooltipPhysical');
    box.style.display = 'none';
  }

  HideLoader() {
    $(".divPhysicalQuotes").animate({ opacity: 1 }, 500);
    $("#loaderPhysical").hide();
  }

  override LoadData() {
    var shouldIncreaseVariationWidth: boolean = false;

    $("#loaderPhysical").css("top", top + "px");
    $(".divPhysicalQuotes").animate({ opacity: 0.4 }, 500);

    this.quotesService.GetProductQuotes(2).subscribe((data) => {
      const jsonGetProductQuotes = JSON.parse(JSON.stringify(data));

      let productCodes: Array<string> = [];

      jsonGetProductQuotes.forEach(line => {
        productCodes.push(line.importCode);
      });

      this.quotesService.GetLastPricePhy(productCodes).subscribe((data) => {
        const jsonQuotesService = JSON.parse(JSON.stringify(data));

        let i: number = 1;

        var tbody = $('.table_quotes_physical_body');
        var oddBGColor = 'FFFFFF';
        var evenBGColor = 'F3F5F6';
        var isLastRow = false;

        jsonQuotesService.forEach(line => {
          var bgColor = (i % 2 !== 0) ? oddBGColor : evenBGColor;
          isLastRow = (i == data.length);

          var variation = parseFloat(line.variation);
          var variationAbs = Math.abs(variation);
          var lastRowBorderLeft = isLastRow ? "border-bottom-left-radius: 10px;" : "";
          var lastRowBorderRight = isLastRow ? "border-bottom-right-radius: 10px;" : "";
          var variationSign = (variation < 0 ? "-" : (variation > 0 ? "+" : ""));
          var backgroundColor = (variation < 0 ? "#E00000" : (variation > 0 ? "#00840D" : "#00840D"));
          var specialStyleDiv = '';
          var specialStyleLabelSign = '';
          var lblVariationSignWidth = '';
          var lblVariationValue_PaddingRight = '';
          var lblVariationValue_PaddingLeft = '';
          var divVariationPaddingRight = '';

          lblVariationSignWidth = (variationSign == "") ? "0" : "12px";
          lblVariationValue_PaddingLeft = (line.variation == 0) ? "4px" : "0";
          lblVariationValue_PaddingRight = (line.variation == 0) ? "0" : "4px";
          divVariationPaddingRight = (line.variation == 0) ? "0" : "4px";

          if (variationAbs >= 10) {
            shouldIncreaseVariationWidth = true;
            specialStyleDiv = "padding-right:4px;";
            specialStyleLabelSign = "margin-right:2px;padding-left:0;";
          }

          line.variation = Math.abs(line.variation).toFixed(2);

          var productCode = line.codeProduct;
          var productName = this.GetProductName(jsonGetProductQuotes, productCode);

          let factory: ComponentFactory<PhysicalQuotesLineComponent> = this.resolver.resolveComponentFactory(PhysicalQuotesLineComponent);
          let myLine = this.table_quotes_physical_body_Placeholder.createComponent(factory);

          myLine.instance.bgColor = bgColor;
          myLine.instance.backgroundColor = backgroundColor;
          myLine.instance.lastRowBorderLeft = lastRowBorderLeft;
          myLine.instance.lastRowBorderRight = lastRowBorderRight;
          myLine.instance.productName = productName;
          myLine.instance.productCode = productCode;
          myLine.instance.specialStyleDiv = specialStyleDiv;
          myLine.instance.specialStyleLabelSign = specialStyleLabelSign;
          myLine.instance.variationSign = variationSign;
          myLine.instance.line = line;
          myLine.instance.i = i;

          i++;
        });

        var top: number = $(".divPhysicalQuotes").height() / 2;
        $("#loaderPhysical").css("top", top + "px");
        $("#loaderPhysical").show();

        setTimeout(this.HideLoader, 1000);
      });
    });
  }

  GetProductName(products, codeProduct: string) {
    let productName: string = '';

    products.forEach(prod => {      
      if (prod.importCode == codeProduct) {        
        productName = prod.nameProduct;

        if (prod.harvest != null && prod.harvest != "") {
          productName += " - " + prod.harvest;
        }
      }
    });

    return productName;
  }

  override SetTitle() {
    this.tableTitle = 'Physique';
    this.dateLastUpdate = '';
  }

  RefreshPrices() {
    Array.from(document.querySelectorAll(".table_quotes_physical_body .lblPriceVariation")).forEach((lblPriceVariation, index) => {
      var currentValuePrice: number = parseFloat($("#lblPrice_" + index).text());
      var valueToReachVariation: number = (this.RandomNumberInRange(0, 1000) / 100) * this.PositiveOrNegative();
      var valueToReachPrice: number = parseFloat((currentValuePrice + valueToReachVariation).toFixed(2));

      this.UpdateNumber(lblPriceVariation.id, index, valueToReachVariation, true);
      this.UpdateNumber("lblPrice_" + index, index, valueToReachPrice, false);
    })
  }

  PositiveOrNegative() {
    return Math.round(Math.random()) * 2 - 1;
  }

  RandomNumberInRange(start, end) {
    if (start > end)
      start++;
    else
      end++;

    return Math.floor((start + Math.random() * (end - start)));
  }

  UpdateNumber(id, index: number, valueToReach: number, isVariation: boolean) {
    var elt = document.getElementById(id);
    var currentValue: number = Number(document.getElementById(id).innerText);
    var endNbrDecimalPart: number = parseInt(currentValue.toString().split(".").pop());

    if (isVariation) {
      var bgColor: string = (valueToReach < 0 ? "E00000" : (valueToReach > 0 ? "00840D" : "00840D"));

      $("#lblVariationSign_" + index).text((valueToReach > 0) ? "+" : (valueToReach < 0) ? "-" : "=");
      $("#lblVariationSign_" + index).css("background-color", "#" + bgColor);
      $("#lblVariationValue_" + index).css("background-color", "#" + bgColor);
    }

    this.UpdateNumberRecursive_IntPart(currentValue, valueToReach, id, elt, endNbrDecimalPart, currentValue <= valueToReach, index, isVariation);
  }

  UpdateNumberRecursive_IntPart(currentValue: number, valueToReach: number, id: string, elt, endNbrDecimalPart: number, increase: boolean, index: number, isVariation: boolean) {
    var finished: boolean = false;

    if (increase) { // Si le prix augmente
      if (parseInt(currentValue.toString().split(".")[0]) <= valueToReach) {  // Si on doit encore augmenter le prix
        elt.innerText = currentValue.toFixed(2).replace("-", "");

        var that = this;
        setTimeout(function () {
          that.UpdateNumberRecursive_IntPart(currentValue + 1, valueToReach, id, elt, endNbrDecimalPart, increase, index, isVariation);
        }, this.animationSpeed);
      }
      else
        finished = true;
    }
    else { // Si le prix baisse
      if (currentValue >= valueToReach) { // Si on doit encore baisser le prix
        elt.innerText = currentValue.toFixed(2).replace("-", "");

        var that = this;
        setTimeout(function () {
          that.UpdateNumberRecursive_IntPart(currentValue - 1, valueToReach, id, elt, endNbrDecimalPart, increase, index, isVariation);
        }, this.animationSpeed);
      }
      else
        finished = true;
    }

    if (finished) { // Si on a terminé l'augmentation ou la baisse du prix pour la partie entière, on passe à la modification des décimales
      var decimalValue: number = parseInt(valueToReach.toFixed(2).toString().split(".")[1]);

      if (currentValue < 0)
        elt.innerText = elt.innerText.replace("-", "");

      if (isNaN(decimalValue))
        decimalValue = 0;

      //if ((!isVariation) && decimalValue < 10) {
      //decimalValue = decimalValue*10;
      //}
      //}

      this.UpdateNumber_DecimalPart(id, decimalValue, increase, index);
    }
  }

  UpdateNumber_DecimalPart(id, decimalValue: number, increase: boolean, index: number) {
    var elt = document.getElementById(id);
    var endNbrDecimalPart = decimalValue;

    this.UpdateNumberRecursive_DecimalPart(increase ? 0 : 99, endNbrDecimalPart, elt, increase, index);
  }

  UpdateNumberRecursive_DecimalPart(i, endNbrDecimalPart: number, elt, increase: boolean, index: number) {
    var finished: boolean = false;

    if (increase) {
      if (i <= endNbrDecimalPart && i <= 99) {
        var val: string;

        if (i < 10)
          val = "0" + i.toString();
        else
          val = i.toString();

        elt.innerText = parseInt(elt.innerText) + "." + val;

        var that = this;
        setTimeout(function () {
          that.UpdateNumberRecursive_DecimalPart(i + 1, endNbrDecimalPart, elt, increase, index);
        }, this.animationSpeed);
      }
      else
        finished = true;
    }
    else {
      if (i >= endNbrDecimalPart && i >= 0) {
        var val: string;

        if (i < 10)
          val = "0" + i.toString();
        else
          val = i.toString();

        elt.innerText = parseInt(elt.innerText) + "." + val;

        var that = this;
        setTimeout(function () {
          that.UpdateNumberRecursive_DecimalPart(i - 1, endNbrDecimalPart, elt, increase, index);
        }, this.animationSpeed);
      }
      else
        finished = true;
    }

    if (finished) {
      this.dateLastUpdate = new Date().toLocaleDateString("fr-FR");
      $("#lblVariationValue_" + index).text(parseFloat($("#lblVariationValue_" + index).text()).toFixed(2));
      $("#lblPrice_" + index).text(parseFloat($("#lblPrice_" + index).text()).toFixed(2));
    }
  }
}
