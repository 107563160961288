<div class="content-container">
  <div class="">
    <div class="title-video">
      <h4>Panorama de marché</h4>
    </div>
    <hr>
    <div class="video">
      <iframe
        src="https://www.youtube-nocookie.com/embed/playlist?list=PLCCZqn4YLe_SQa1HLPHhJQuj-cmFScP71&feature=shared&?modestbranding=1"
        frameborder="0" class="iframe-video"></iframe>
    </div>
  </div>

  <div class="explication-video">
    <div>
      <p class="title"> Qu'est ce qu'un <b class="orange"> "Panorama de marché" </b> ?</p>

      <p>Nos panoramas de marché trimestriels sont des événements incontournables pour les professionnels du secteur
        agricole. Animés par nos consultants et analystes, ces visioconférences offrent un aperçu complet des marchés
        des matières premières agricoles.</p>

      <p>Les replays sont disponibles sur notre plateforme pour les abonnés à notre service <b class="orange">Agritel
          Farmer PLUS</b>. Vous pouvez les consulter à tout moment, où que vous soyez.</p>

      <p> Ne manquez pas nos prochains panoramas ! Assurez-vous de vérifier régulièrement vos e-mails pour vous inscrire
        à la prochaine réunion.</p>
      <hr>
      <p>Pour consulter l'historique des " Panorama de marché " cliquez sur l'icône <mat-icon aria-hidden="false"
          aria-label="Example home icon" fontIcon="playlist_play"></mat-icon> dans le coin supérieur droit de la vidéo.
      </p>
    </div>
  </div>
</div>