import { CanActivateFn, Router } from '@angular/router';
import { CompanyService } from '../../../services/http/company.service';
import { inject } from '@angular/core';
import { NotificationService } from '../../components/notifications/notification.service';

export const subscriptionExpiryGuard: CanActivateFn = () => {
  const companyService = inject(CompanyService); 
  const notificationService = inject(NotificationService);
  const router = inject(Router); 

  companyService.PlanHasExpired().subscribe({
    next:(value: boolean)=>{
      if(value === true){
        router.navigateByUrl('expire'); 
      }
    },
    error: (err: { status: number; }) => {
      if (err.status !== 401) {
        notificationService.ShowError("Erreur lors du chargement des informations.", "");
      }
    },
    complete: () => {},
  })
  return true;
};
