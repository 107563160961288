import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-form-minute-chrono',
  templateUrl: './form-minute-chrono.component.html',
  styleUrl: './form-minute-chrono.component.scss'
})
export class FormMinuteChronoComponent implements OnInit {

  ngOnInit(): void {

  }
  askForm = new FormGroup({
    question: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(500)]),
  })


  onClick() {
    console.log(this.askForm.value);
  }

}
