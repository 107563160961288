
<div class="modal-container">
  <div class="header-modal">
    <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="close" class="icon"  mat-dialog-close></mat-icon  >

  </div>
  <div class="image-container">
  <img [src]="data.image" alt="image analyse" class="image">
    
  </div>

</div>