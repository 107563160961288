import { Component, Injectable, Input, OnInit } from '@angular/core';
import { GetContributionAvisRequest } from '../../../model/contribution/GetContributionAvisRequest';
import { NotificationService } from '../../shared/components/notifications/notification.service';
import { CompanyService } from '../../services/http/company.service';
import { ContributionService } from '../../services/http/contribution.service';
import { AnalysisComponent } from '../analysis/analysis.component';
import { Opinion } from 'src/model/analysis/Opinion';

@Component({
  selector: 'app-opinion',
  templateUrl: './avis.component.html',
  styleUrls: ['./avis.component.scss']
})

@Injectable()
export class AvisComponent implements OnInit {

  analysisComponent: AnalysisComponent;

  title: string = '';
  text: string = '';
  planHasExpired: boolean;
  @Input() productId: number;

  constructor(private notifyService: NotificationService, private contributionService: ContributionService, private companyService: CompanyService, ) { }

  ngOnInit(): void {
    this.companyService.PlanHasExpired().subscribe((data: boolean) => {
      this.planHasExpired = data;

      this.LoadData(this.productId);
    },
      (err: { status: number; }) => {
        if (err.status !== 401) {
          console.error("Erreur:", err)
        }
      });
  }

  ngAfterViewInit() {
  }

  LoadData(productId: number) {
    let request: GetContributionAvisRequest = new GetContributionAvisRequest();
    request.ProductId = productId;

    this.contributionService.GetContributionAvis(request).subscribe((data: Opinion) => {
      this.title = data.title;
      this.text = data.text;
    },
      (err: { status: number; }) => {
        if (err.status !== 401) {
          console.error("Erreur:", err)

        }
      });
  }
}
