export class Strategy {
  
  public number: number;
  public date: string = '';
  public campaignId: number;
  public percentCall: number;
  public productId: number;
  public title: string;
  public text: string;
  public year: number;
  public percentSales: number;
  public percentSalesRounded?: number;
}
