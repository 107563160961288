import { Component, ComponentFactory, ComponentFactoryResolver, Injectable, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { GlobalFunctions } from '../../../../../global';
import { Dates } from '../../../../../tools/Date';
import { ProductPriceLineComponent } from '../../product_price_line/product_price_line.component';

@Component({
  selector: 'product_table_euronext',
  templateUrl: './product_table_euronext.component.html',
  styleUrls: ['./product_table_euronext.component.scss']
})

@Injectable()
export class ProductTableEuronextComponent   implements OnInit {
  @Input() productName: string = '';
  @Input() productCode: string = '';  
  @Input() productType: string;
  @Input() unit: string;
  @Input() tbodyId: string = '';
  @Input() index: number;  
  @Input() divProductPricesEuronextPaddingRight: string = '';
  @Input() productTooltip: string = '';
  data: Array<any> =[];
  
  constructor(private resolver: ComponentFactoryResolver) {
  }

  @ViewChild('productPricesLinesEuronext_Placeholder', { read: ViewContainerRef })
  public productPricesLinesEuronext_Placeholder: ViewContainerRef;

   ngOnInit(): void {
  }

   ngAfterViewInit() {
  }

  ImgMouseover() {
    this.ShowProductTooltips_Euronext();
  }

  DivTooltipFuturesEuronext_MouseOver() {
    this.ShowProductTooltips_Euronext();
  }

  DivTooltipFuturesEuronext_MouseLeave() {
    this.HideProductTooltips_Euronext();
  }

  DivTooltipFuturesEuronext_Click() {
    this.HideAllProductTooltips_Euronext();
  }

  ShowProductTooltips_Euronext() {
    document.getElementById('divTooltipFuturesEuronext_' + this.productCode).style['display'] = 'block';
  }

  HideProductTooltips_Euronext() {
    document.getElementById('divTooltipFuturesEuronext_' + this.productCode).style['display'] = 'none';
  }

  HideAllProductTooltips_Euronext() {
    Array.from(document.querySelectorAll('.divTooltipFuturesEuronext')).forEach(function (val: HTMLElement) {
      val.style.display = 'none';
    });  
  }

  ClearData() {
    if (this.productPricesLinesEuronext_Placeholder)
      this.productPricesLinesEuronext_Placeholder.clear();
  }

  LoadData(isRefresh: boolean) {
    var oddBGColor = 'FFFFFF';
    var evenBGColor = 'F3F5F6';    
    var index: number = 0;

    this.data.forEach(line => {
      if (!isRefresh) {      
        let factory: ComponentFactory<ProductPriceLineComponent> = this.resolver.resolveComponentFactory(ProductPriceLineComponent);
        let product_price_line = this.productPricesLinesEuronext_Placeholder.createComponent(factory);
      
        var bgColorRow: string = (index % 2 == 0) ? oddBGColor : evenBGColor;
        
        var dateExpiry: Date = new Date(line.realDateExpiry);
        var monthNumberExpiry: number = dateExpiry.getMonth() + 1;
        var yearExpiry: string = dateExpiry.getFullYear().toString().substring(2);
        var cellCssClass: string = (index < 5) ? "tdCellProductPriceVisible" : "tdCellProductPriceHidden";
        var bgColorVariation: string = (line.variation < 0 ? "E00000" : (line.variation > 0 ? "00840D" : "00840D"));

        product_price_line.instance.codeExpiry = line.codeExpriy;
        product_price_line.instance.textExpiry = GlobalFunctions.CapitalizeFirstLetter(Dates.GlobalFunctionsDates.GetMonthShortName(monthNumberExpiry)) + " " + yearExpiry;
        product_price_line.instance.variation = Math.abs(line.variation).toFixed(2);
        product_price_line.instance.variationSign = (line.variation > 0) ? "+" : (line.variation < 0)?"-":"";
        product_price_line.instance.price = line.close.toFixed(2);
        product_price_line.instance.bgColorRow = bgColorRow;
        product_price_line.instance.index = index;
        product_price_line.instance.rowIndex = index+1;
        product_price_line.instance.cellCssClass = cellCssClass;
        product_price_line.instance.bgColorVariation = bgColorVariation;
        product_price_line.instance.productCode = this.productCode;
        product_price_line.instance.productName = this.productName.trim();
        product_price_line.instance.productType = "Euronext";
        product_price_line.instance.unit = this.unit;
      }

      index++;
    });
  }
}
