<app-header></app-header>
<div class="" id="quotes-container">
  <div class="left-menu">
    <leftMenu></leftMenu>
  </div>


  <div class="background-image-container">
    <app-replay-nav></app-replay-nav>
    <app-minute-chrono></app-minute-chrono>
  </div>
  <div class="background-text-container">
    <div class="text-container">
      <img src="../../assets/images/logo/agritel-plus/agritel_plus_large_logomark_full colour_rgb.png"
        alt="Logo agritel plus " width="150" class="agritel-logo-overlay">
      <h1>Explorez nos Replays de <b>Panoramas de Marchés</b> avec <b>Agritel Farmer Plus !</b></h1>
      <p class="text">En plus des avantages offerts par Agritel Farmer, Agritel Farmer Plus vous donne accès
        à une hotline illimitée et à des panoramas de marché trimestriels.
      </p>

      <p class="text">Pour en savoir plus, contactez Céline par téléphone au 07 66 39 29 79 ou par e-mail à
        celine&#64;farmer.agritel.com</p>

      <a [routerLink]="['../replay/3-min-chrono']" class="orange text">Retourner à l'accueil</a>
    </div>
  </div>

</div>