<div class="account">
  <app-account-nav></app-account-nav>
  <div class="container-my-account">
    <div class="information">
      <app-informations></app-informations>
  
    </div>
    <div class="password">
      <app-change-password></app-change-password>
  
    </div>
    <div class="subscription">
      <app-my-plan></app-my-plan>
  
    </div>
  </div>
</div>
