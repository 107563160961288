<div class="blured">
  <app-header></app-header>
</div>
<div class="container" id="quotes-container">

  <div class="left-menu">
    <leftMenu></leftMenu>
  </div>

  <div class="expired-modal-overlay">
  </div>
  <div class="expired-modal-container">
    <div class="background-text-container">
      <div class="text-container">
        <img src="../../../../assets/images/logo/agritel-farmer/agritelFullColoredLogoWithText.png"
          alt="Logo agritel plus " width="450">
        <br>
        <div class="expired-modal-header">
          <h2>Votre abonnement a expiré.</h2>
        </div>

        <p class="text"> <b>Souhaitez-vous renouveler votre abonnement ?</b> <br> Pour connaître la procédure à suivre,
          n’hésitez pas
          à contacter notre service commercial par téléphone ou par email.</p>

        <div class="expired-modal-contact-info-container">
          <p class="expired-modal-contact-info">07 66 39 29 79</p>
          <p class="expired-modal-contact-info">sales&#64;farmer.agritel.com</p>
        </div>


      </div>
    </div>
  </div>
</div>