import { Component , Output  } from '@angular/core';

@Component({
  selector: 'app-sunflower',
  templateUrl: './sunflower.component.html',
  styleUrls: ['./sunflower.component.scss']
})
export class SunflowerComponent {
  @Output() sentProductId: number = 7;
  classBlurred: any;

}
