export class ExpertOpinion {
  constructor(){
  }

  public title: string = '';
  public text: string = '';
  public horizon1Label: string = '';
  public horizon2Label: string = '';
  public horizon1Trend: number;
  public horizon2Trend: number;
  public classImgTrendHorizon1: string = '';
  public classImgTrendHorizon2: string = '';
  public classBackgroundTrendHorizon1: string = '';
  public classBackgroundTrendHorizon2: string = '';
}
