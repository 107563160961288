import { Injectable } from '@angular/core';
import { SaveUXRequest } from '../../../model/service/SaveUXRequest';
import { Service } from './service';

@Injectable({
  providedIn: 'root'
})

export class UXService extends Service {

  override controller = 'UX';

  SaveUX(saveUXRequest: SaveUXRequest): any {

    let data: any = this.HttpPostGeneric(this.controller, "SaveUX", saveUXRequest);

    JSON.parse(JSON.stringify(data))

    return data;
  }
}



