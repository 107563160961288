<div class="content-container">
  <div class="">
    <div class="title-video">
      <h4>3 Min Chrono</h4>
    </div>
    <hr>
    <div class="video">
      <iframe
        src="https://www.youtube-nocookie.com/embed/playlist?list=PLCCZqn4YLe_TDvIFojeCYezQyREKF4Cyo&feature=shared&?modestbranding=1"
        frameborder="0" class="iframe-video"></iframe>
    </div>
  </div>

  <div class="explication-video">
    <div>
      <p class="title"> Qu'est-ce que <b class="orange"> "Le 3 min Chrono"</b> ?</p>
      <p> Chaque jeudi, découvrez nos vidéos <b class="orange">"Le 3 min Chrono"</b>. 
        <br>
        Ces courtes vidéos vous apportent des réponses rapides et pertinentes à vos questions sur le marché des grains.
      </p>
      <h6> <b>Posez vos questions :</b> </h6>
      <p> Rendez-vous sur notre page dédiée en cliquant sur le bouton ci-dessous pour poser vos questions chaque semaine
        ! </p>
        
        <p class="text"> <a href="https://alabordage.captainfarmer.com/3_min_chrono_question-0" target="_blank"
          class="buttonFullTextBold ask-question">
          Posez vos question ici ! </a></p>

      <hr>
      <p>Pour consulter l'historique des " 3 Min Chrono " cliquez sur l'icône <mat-icon aria-hidden="false"
          aria-label="Example home icon" fontIcon="playlist_play"></mat-icon> dans le coin supérieur droit de la vidéo.
      </p>

    </div>
  </div>
</div>