<div class="content-container">
  <div class="">
    <div class="title-video">
      <h4>La compagnie des farmers</h4>
    </div>
    <hr>
    <div class="video">
      <iframe
        src="https://www.youtube-nocookie.com/embed/playlist?list=PLCCZqn4YLe_QbWVz83KykNgNmeoeh15AC&feature=shared&?modestbranding=1"
        frameborder="0" class="iframe-video"></iframe>
    </div>
  </div>

  <div class="explication-video">
    <div>
      <div>
        <p class="title">Qu'est-ce que <b class="orange"> "La Compagnie des Farmers"</b> ? </p>
        <p><b class="orange"> "La Compagnie des Farmers"</b> est une section exclusive de notre
          plateforme Agritel Farmer. Elle vous donne accès aux replays de nos webinaires mensuels, animés par nos
          consultants experts de marché. </p>
        <p> Voici ce que vous y trouverez :</p>
        <ul>
          <li><b>Analyse de Marché :</b>  Découvrez les tendances, les prix et les facteurs qui influencent le marché des
            grains.
          </li>
          <li><b>Fondamentaux : </b> Apprenez comment l'offre et la demande impactent actuellement les cours.</li>
          <li> <b>Weather Market : </b>Comprenez comment la météo impacte les cultures chez les principaux pays producteurs.
          </li>
        </ul>
        <hr>
        <p>Pour consulter l'historique des vidéos " La Compagnie des Farmers " cliquez sur l'icône <mat-icon aria-hidden="false"
          aria-label="Example home icon" fontIcon="playlist_play"></mat-icon> dans le coin supérieur droit de la vidéo.
      </p>
      </div>
    </div>
  </div>