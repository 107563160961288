import { Component, Injectable, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UpdateCGUCGValidation } from 'src/model/users/TermsAndConditions';
import { NotificationService } from 'src/app/shared/components/notifications/notification.service';
import { UserService } from 'src/app/services/http/user.service';

@Component({
  selector: 'informationNotice',
  templateUrl: './information_notice.component.html',
  styleUrls: ['./information_notice.component.scss']
})

@Injectable()
export class InformationNoticeComponent implements OnInit {

  constructor(private userService: UserService) { }

  errorMessage = '';
  productName = '';
  nbProducts = 0;
  isPopup: boolean = false;
  isPopupClosing: boolean = false;
  title: string = '';
  defaultProducts: number;

  dialogRef: MatDialogRef<InformationNoticeComponent>;

  formTermsOfService = new FormGroup({
    isCGVValidated: new FormControl(false, Validators.requiredTrue)
  })


  ngOnInit(): void { }

  OnClose(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.userService.UpdatePolices({
      IsCGUValidated: true,
      IsCGVValidated: this.formTermsOfService.value.isCGVValidated,
    }).subscribe({
      next: (value: UpdateCGUCGValidation) => {},
      error(err: { status: number; }) {
        if (err.status != 401) {
          this.notificationService.ShowError("Erreur", "Erreur lors de l'acceptation des conditions de générales et de ventes")
        }

      },

      complete() {
        console.info("Terms of sales and conditions accepted");
      },
    })

  }

}
