import { Component, Output } from '@angular/core';

@Component({
  selector: 'app-common-wheat',
  templateUrl: './common-wheat.component.html',
  styleUrls: ['./common-wheat.component.scss']
})
  // Blé tendre
export class CommonWheatComponent {
  
  @Output() sentProductId: number = 1;
  @Output() strategyTitle : string = 'Opinion'; 

  classBlurred: any;

}
