import { Component, Injectable, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GetContributionDecryptRequest } from '../../../model/contribution/GetContributionDecryptRequest';
import { NotificationService } from '../../shared/components/notifications/notification.service';
import { ContributionService } from '../../services/http/contribution.service';
import { AnalysisComponent } from '../analysis/analysis.component';

@Component({
  selector: 'app-decrypt',
  templateUrl: './decrypt_detail.component.html',
  styleUrls: ['./decrypt_detail.component.scss']
})

@Injectable()
export class DecryptDetailComponent implements OnInit {
  form: FormGroup | undefined;

  analysisComponent: AnalysisComponent;

  title: string = '';
  text: string = '';
  @Input() productId: number;

  constructor(private notifyService: NotificationService, private contributionService: ContributionService) {
  }

  ngOnInit(): void {
    this.LoadData(this.productId)

  }

  LoadData(productId: number) {
    let request: GetContributionDecryptRequest = new GetContributionDecryptRequest();
    request.ProductId = productId;

    this.contributionService.GetContributionDecrypt(request).subscribe((data) => {
      this.title = data.title;
      this.text = data.text;
    },
      err => {
        if (err.status !== 401) {
          this.notifyService.ShowError("Erreur", "");
        }
      });
  }
}
