import { Component, OnInit } from '@angular/core';
import { GlobalFunctions, GlobalParams } from '../global';
import { User } from '../tools/User';
import { Configuration } from './configuration';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs';
import { UXService } from './services/http/UX.service';
import { SaveUXRequest } from 'src/model/service/SaveUXRequest';
import { OS } from 'src/tools/OS';
import { GlobalFunctionsToken } from 'src/tools/Token';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'Agritel Farmer';
  websiteBaseURL: string = '';
  apiEndpoint: string = '';
  agritelApiEndpoint: string = '';
  advisorsImagesUrl: string = '';
  analysisImagesUrl: string = '';
  logUserExperience: boolean = false;
  showToggleTheme: boolean = false;
  forceHttps: boolean = false;
  environment: string = '';

  advisorInformationAgritelFarmer: {
    titleAdvisor: string,
    nameAdvisor: string,
    phoneAdvisor: string,
    emailAdvisor: string,
  };
  advisorInformationAgritelFarmerPlus: {
    titleAdvisor: string,
    nameAdvisor: string,
    phoneAdvisor: string,
    emailAdvisor: string,
  };
  saveUxRequest: SaveUXRequest = {
    Date: new Date(),
    NamePage: '',
    IdPlatform: OS.GlobalFunctionsOS.MobileOrDesktop(),
    Environment: GlobalFunctions.GetEnvironment(),
  }

  constructor(public readonly config: Configuration, private router: Router, private uxService: UXService) {
    this.websiteBaseURL = config.websiteBaseURL;
    this.apiEndpoint = config.apiEndpoint;
    this.agritelApiEndpoint = config.agritelApiEndpoint;
    this.advisorsImagesUrl = config.advisorsImagesUrl;
    this.analysisImagesUrl = config.analysisImagesUrl;
    this.logUserExperience = config.logUserExperience;
    this.showToggleTheme = config.showToggleTheme;
    this.environment = config.environment;
    this.forceHttps = config.forceHttps;
    this.advisorInformationAgritelFarmer = config.advisorInformationAgritelFarmer;
    this.advisorInformationAgritelFarmerPlus = config.advisorInformationAgritelFarmerPlus;

    GlobalFunctions.SetWebsiteBaseURL(this.websiteBaseURL);
    GlobalFunctions.SetApiEndpoint(this.apiEndpoint);
    GlobalFunctions.SetAgritelApiEndpoint(this.agritelApiEndpoint);
    GlobalFunctions.SetAdvisorsImagesUrl(this.advisorsImagesUrl);
    GlobalFunctions.SetAnalysisImagesUrl(this.analysisImagesUrl);
    GlobalFunctions.SetLogUserExperience(this.logUserExperience);
    GlobalFunctions.SetShowToggleTheme(this.showToggleTheme);
    GlobalFunctions.SetForceHttps(this.forceHttps);
    GlobalFunctions.SetEnvironment(this.environment);
    GlobalFunctions.SetAdvisorInformationAgritelFarmer(this.advisorInformationAgritelFarmer);
    GlobalFunctions.SetAdvisorInformationAgritelFarmerPlus(this.advisorInformationAgritelFarmerPlus);

    this.uxService.apiEndPoint = GlobalFunctions.GetApiEndpoint(true);
    this.saveUxRequest.Environment = this.environment;
  }

  ngOnInit() {
    // Force redirection to Https if needed
    if (GlobalParams.ForceHttps == true && location.protocol == "http:")
      window.location.href = location.href.replace('http', 'https');
    this.getLastEventRouter();

  }

  ngDoCheck() {
    this.redirectToSpecificPage();
  }

  getLastEventRouter() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd))
      .subscribe({
        next: (value: NavigationEnd) => {
          if (value.urlAfterRedirects != "/connexion") {
            this.saveUxRequest.NamePage = value.urlAfterRedirects;
            this.sendSaveUxRequest();
          }
        },
        error: (err: number) => {
          if (err !== 401) {
            console.error("Error: " + err);
          }
        },
        complete: () => { },
      })
  }
  sendSaveUxRequest() {
    this.uxService.SaveUX(this.saveUxRequest).subscribe({
      error: (err: number) => {
        if (err !== 401) {
        }
      },
      complete: () => {
        console.info("Informations sended")
      },
    })
  }
  GetIsConnected(): boolean {
    return User.GlobalFunctionsUser.GetIsConnected();
  }


  GetName(): string {
    return User.GlobalFunctionsUser.GetFirstName();
  }

  redirectToSpecificPage() {
    const nameLocalStorage = "redirectedUrl";
    this.router.events.forEach((events) => {
      if (events instanceof NavigationStart && events.url != "/connexion" && events.url != "/mot-de-passe") {
        localStorage.setItem(nameLocalStorage, events.url)
      }
    })
  }
}
