export class StringTools {
  static ConcatUrlEnsureSimpleSlash(...args) {
    let result = '';

    for (let i = 0; i < args.length; i++) {
      // A partir du 2ème élement dans le tableau de string : si ça ne commence pas par "/" on l'ajoute au début
      if (i > 0 && !args[i].startsWith('/'))
        args[i] = '/' + args[i];

      // Pour tous les élements du tableau : on supprime le "/" à la fin si ça se termine par un "/"
      while (args[i].endsWith('/'))
        args[i] = args[i].slice(0, -1);
    }

    // On joint les éléments du tableau
    result = args.join('');

    return result;
  }

  

static urlRegex: RegExp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi

  /**
  * Truncate link to a clickable text message. 
  * @param alertMessage
  * @returns string or empty quotes.
  */
  static truncateLinkFromText(alertMessage: string): string {
    const messageToArray = alertMessage.split(' ');
    messageToArray.forEach((element, index) => {
      if (this.urlRegex.test(element)) {
        return messageToArray.splice(index, 2)
      } else {
        return messageToArray.join(" ");
      }
    });
    return messageToArray.join(" ");
  }


  /**
* Check to see if there is a link in the message. 
* @param alertLink
* @returns string or empty quotes.
*/

  static clickableLinkIfExist(alertLink: string): string {
    const message = alertLink.split(' ');
    const getLastPartMessage = message.slice(-1).join();
    let filteredElement: RegExpMatchArray | string = getLastPartMessage.match(this.urlRegex);
    if (filteredElement != null) {
      filteredElement = filteredElement.join();
      return filteredElement

    }
    return '';
  }


}


