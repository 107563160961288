<div class="container-login-teaser">
  <div>
    <label class="title">
      Prenez soins de vos ventes comme vous le faites avec vos cultures
    </label>
  </div>
  <div>
    <label class="text">
      Vos cultures méritent d'être vendues aussi bien qu'elles ont été entretenues. Embarquez dès maintenant dans l'aventure ! <b>Améliorez vos performances</b> grâce au conseil et à l'expertise de marché de Agritel Farmer.
    </label>
  </div>
  <div class="button-action">
    <a href="https://alabordage.captainfarmer.com/demande-dinformation-agritel-farmer"
    class="btn btn-block-login_teaser  buttonFullTextBoldUppercase" target="_blank">Découvrez la plateforme</a>
  </div>
  
</div>