<div class="replay-nav">
  <ul class="list-container">
    <li>
      <a [routerLink]="['3-min-chrono']" routerLinkActive="active-link" class="replay-nav-links">3 Min Chrono</a>
    </li>
    <li>
      <a [routerLink]="['compagnie-farmers']" routerLinkActive="active-link" class="replay-nav-links">La compagnie des farmers</a>
    </li>
    <li class="panoramas">
      <a [routerLink]="['panoramas-marche']" routerLinkActive="active-link" class="replay-nav-links">  
    
          <img src="../../../assets/images/logo/agritel-plus/agritelPlusSmallLogo.png" alt="Agritel Plus Logo" width="35" class="agritel-logo">  Panorama de marché

          <svg width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="lock-icon" [style.display]="toShow"> <path
            d="M2 16C2 13.1716 2 11.7574 2.87868 10.8787C3.75736 10 5.17157 10 8 10H16C18.8284 10 20.2426 10 21.1213 10.8787C22 11.7574 22 13.1716 22 16C22 18.8284 22 20.2426 21.1213 21.1213C20.2426 22 18.8284 22 16 22H8C5.17157 22 3.75736 22 2.87868 21.1213C2 20.2426 2 18.8284 2 16Z"
            />
          <circle cx="12" cy="16" r="2" />
          <path d="M6 10V8C6 4.68629 8.68629 2 12 2C15.3137 2 18 4.68629 18 8V10" stroke-linecap="round" /></svg> 
        
          
      </a>
    </li>
  </ul>
</div>