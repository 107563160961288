<div class="container-my-informations">
  <div class="container-title">
    <h5>Mon compte</h5>
  </div>
  <div class="container-form">
    <div class="my_informations-div transition-fade" data-swup="0">
      <div class="form">
        <form [formGroup]="informationForm">
          <mat-form-field appearance="outline" id="mat-form-<field-LastName" class="width_75">
            <mat-label>Nom</mat-label>
            <input type="text" matInput maxlength="100" placeholder="Nom" required="required" name="lastname"
              formControlName="lastname">
          </mat-form-field>

          <mat-form-field appearance="outline" id="mat-form-field-FirstName" class="width_75">
            <mat-label>Prénom</mat-label>
            <input matInput #input maxlength="100" placeholder="Prénom" required="required" id="FirstName"
              name="firstname" formControlName="firstname">
          </mat-form-field>

          <mat-form-field appearance="outline" id="mat-form-field-PhoneNumber" class="width_75">
            <mat-label>Téléphone</mat-label>
            <input matInput #input maxlength="100" required="" placeholder="Téléphone" required="required"
              id="PhoneNumber" formControlName="phoneNumber" name="phoneNumber">
          </mat-form-field>
          <hr>
          <div class="">
            <button type="submit" [disabled]="informationForm.invalid" class="buttonOutlinedTextBoldSmallBlack width_75"
              (click)="SaveData();">Sauvegarder</button>
          </div>
        </form>


      </div>
    </div>
  </div>