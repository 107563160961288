import { ViewContainerRef } from "@angular/core";
import * as $ from 'jquery/dist/jquery.min.js';
import { GlobalEnums, GlobalParams } from "../global";
import { User } from "../tools/User";

export namespace UI {
  export class GlobalFunctionsUI {

    
    public static SetHomeLogged_Placeholder(homeLogged_Placeholder: ViewContainerRef) {
      GlobalParams.homeLogged_Placeholder = homeLogged_Placeholder;
    }

    public static GetHomeLogged_Placeholder(): ViewContainerRef {
      return GlobalParams.homeLogged_Placeholder;
    }

    public static RefreshHeaderName() {
      $("#lbName").text(User.GlobalFunctionsUser.GetFirstName());
    }

  }
}
