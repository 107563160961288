import { Component, Input, OnInit } from '@angular/core';
import { NotificationService } from '../../shared/components/notifications/notification.service';
import { ContributionService } from '../../../app/services/http/contribution.service';
import { KeyPoints } from '../../../model/analysis/KeyPoints';
import { GetContributionKeyPoints } from '../../../model/contribution/GetContributionKeyPoints';
import { AnalysisComponent } from '../analysis/analysis.component';

@Component({
  selector: 'app-keypoints',
  templateUrl: './keypoints.component.html',
  styleUrls: ['./keypoints.component.scss']
})
export class KeypointsComponent implements OnInit {
  
  analysisComponent: AnalysisComponent;
  title : string = ""; 
  text : string =""; 
  titleComponent = "Points clés à surveiller"
  keyPointsArray : KeyPoints[] = []; 
  @Input() productId: number;


  constructor(private notifyService : NotificationService , private contributionService: ContributionService) { 
  }
  

  ngOnInit(): void {
    this.LoadData(this.productId)
  }
  
  ngAfterViewInit() {
    // this.LoadData(GlobalParams.ProductId);
  }

  
  LoadData(productId: number){
    let request: GetContributionKeyPoints = new GetContributionKeyPoints(); 
    request.ProductId = productId; 

    this.contributionService.GetContributionKeyPoints(request).subscribe({
      next:(data: KeyPoints[]) =>{
        data.forEach((element) => {
          this.keyPointsArray.push(element)
          
        });
      }, 
      error: (err) => {
        if(err.status != 401){
          this.notifyService.ShowError("Erreur","Erreur lors du chargement des informations.")
        }
      },
      complete: () => {}
    })

  }

  

}
