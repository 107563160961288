<ng-container #manageMyProductsPlaceholder></ng-container>
<div class="outer centerPadded divStrategyHeader" id="divStrategyHeader">
  <ng-container #strategyHeaderPlaceholder></ng-container>
</div>
<div class="wrapper marginBottom" id="divStrategy">
  <div class="first">
    <ng-container #strategyLeftPlaceholder class="first"></ng-container>
  </div>
  <div class="spacer">
  </div>
  <div class="second">
    <ng-container #strategyRightPlaceholder class="second"></ng-container>
  </div>
</div>
