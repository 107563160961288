import { Component , Output } from '@angular/core';

@Component({
  selector: 'app-feed-barley',
  templateUrl: './feed-barley.component.html',
  styleUrls: ['./feed-barley.component.scss']
})
export class FeedBarleyComponent {
  classBlurred: any;

  @Output() sentProductId: number = 4;



}
