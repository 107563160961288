import { Component, Injectable, OnInit } from '@angular/core';
import { NotificationService } from '../../shared/components/notifications/notification.service';
import { CompanyService } from '../../services/http/company.service';
import { UserService } from '../../services/http/user.service';
import { Observable, forkJoin } from 'rxjs';
import { Plan } from 'src/model/Plan';
import { UserPlanID } from 'src/model/users/UserPlanId';

@Component({
  selector: 'app-my-plan',
  templateUrl: './my_plan.component.html',
  styleUrls: ['./my_plan.component.scss']
})

@Injectable()
export class myPlanComponent implements OnInit {
  planId: number;
  planName: string = '';
  isPlanExpired: boolean;
  datePlanExpired: string;
  expiredText: string = "";
  isAgritelFarmerPlus: boolean;
  urlImage: string = "../../../assets/images/logo/agritel-farmer/agritelColoredLogo.png";

  constructor(private companyService: CompanyService, private userService: UserService,
    private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.getAllDatas();
  }

  getAllDatas() {
    const getIsPlanExpired: Observable<boolean> = this.companyService.PlanHasExpired();
    const getDateEndPlan: Observable<Plan> = this.companyService.GetRightEnd();
    const getIdUserProfil: Observable<UserPlanID> = this.userService.GetIdProfil();

    forkJoin([getIsPlanExpired, getDateEndPlan, getIdUserProfil]).subscribe({

      next: (value) => {
        this.isPlanExpired = value[0]
        this.datePlanExpired = value[1].rightEnd
        this.planId = value[2].idProfil
      },
      error: (err) => {
        if (err.status !== 401) {
          this.notificationService.ShowError("Erreur lors du chargement des informations.", "");
        }
      },
      complete: () => {
        console.info("information received");
        this.showOfferDependingOnStatus();
        this.showOfferTextDependingOnStatus();
      },
    })

  }

  showOfferDependingOnStatus() {
    switch (this.planId) {
      case 1:
        this.planName = "Agritel Farmer";
        this.isAgritelFarmerPlus = false;
        this.urlImage = "../../../assets/images/logo/agritel-farmer/agritelColoredLogo.png"; 
        break;
      case 2:
      case 3:
      case 4:
        this.planName = "Agritel Farmer Plus";
        this.isAgritelFarmerPlus = true; 
        this.urlImage = "../../../assets/images/logo/agritel-plus/agritel_plus_large_logomark_full colour_rgb.png"; 
        break;
    }
  }

  showOfferTextDependingOnStatus() {
    switch (this.isPlanExpired) {
      case true:
        this.expiredText = "a expiré";
        break;
      default:
        this.expiredText = this.planId == 4 ? "expirera" : " se renouvelera";
    }
  }
}
