import { HttpContextToken } from '@angular/common/http';
import { Component, Injectable, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GlobalParams } from '../../../../../global';
import { PriceChartPhysicalComponent } from '../../charts/price_chart/price_chart_physical/price_chart_physical.component';

@Component({
  selector: 'physical_quotes_line',
  templateUrl: './physical_quotes_line.component.html',
  styleUrls: ['./physical_quotes_line.component.scss']
})

@Injectable()
export class PhysicalQuotesLineComponent   implements OnInit {
  bgColor: string = '';
  lastRowBorderLeft: string = '';
  productName: string = '';
  productCode: string = '';
  specialStyleDiv: string = '';
  backgroundColor: string = '';
  // divVariationPaddingRight: string = '';
  // lblVariationValue_PaddingLeft: string = '';
  // lblVariationValue_PaddingRight: string = '';
  variationSign: string = '';
  specialStyleLabelSign: string = '';
  lastRowBorderRight: string = '';
  lblVariationSignWidth: string = '';
  i: number;
  
  line: any;
  
  constructor(public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.bgColor = "background-color: #" + this.bgColor;
    this.lastRowBorderLeft = this.lastRowBorderLeft + "vertical-align:middle;";
    // this.divVariationPaddingRight = "10px";
  }

  OpenDialogChartPhysical() {
    GlobalParams.BYPASS_SPINNER = new HttpContextToken(() => false);

    const dialogRef = this.dialog.open(PriceChartPhysicalComponent, {
      width: '1000px',
      position: {
      
      },
      panelClass: 'custom-dialog-container',
      disableClose: false
    });

    dialogRef.componentInstance.dialogRef = dialogRef;
    dialogRef.componentInstance.productCode = this.productCode;
    dialogRef.componentInstance.productName = this.productName;
    dialogRef.componentInstance.pricesType = "Physical";    
  }
}
