<div id="divProductPriceEuronext_{{index}}" class="col-sm-4 mainDivProductPriceEuronext">
  <div class="divProductPricesEuronext" style="{{divProductPricesEuronextPaddingRight}}">
    <div class="row rowProductPriceEuronext">
      <div id="divTitle" class="col-sm-12 divTitleProductPriceEuronext">
        <div class="divTitleProductPriceEuronext2">
          <div class="divTitleProductPriceEuronext3">
            <b>{{productName}}</b>
          </div>
          <div class="divIconChart" onmouseleave="Array.from(document.querySelectorAll('.divTooltipFuturesEuronext')).forEach(function (val) { val.style.display = 'none'; });">
            <mat-icon id="imgInformationEuronext_{{productCode}}" class="imgInformationProductPrice" aria-hidden="false" aria-label="info" fontIcon="info" (mouseover)="ImgMouseover()"></mat-icon>
          </div>
          <div id="divTooltipFuturesEuronext_{{productCode}}" class="divTooltipFuturesEuronext" (mouseover)="DivTooltipFuturesEuronext_MouseOver()" (mouseleave)="DivTooltipFuturesEuronext_MouseLeave()" (click)="DivTooltipFuturesEuronext_Click()">
            <div id="divTooltipPriceFuturesEuronext_{{productCode}}" class="pointedProductFutureEuronext">
              <div id="lblTooltipPriceFuturesEuronext_{{productCode}}" [innerHTML]="productTooltip | safeHtml"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <table class="table table-striped tblProductPrices" id="table_product_price_{{productCode}}">
      <thead>
        <tr class="trProductPricesHeader">
          <th scope="col" class="thProductPricesHeader">
            <label class="lblProductPricesHeader">Date</label>
          </th>
          <th scope="col" class="thProductPricesHeader">
            <label class="lblProductPricesHeader">Prix ({{unit}})</label>
          </th>
          <th scope="col" class="thProductPricesHeader">
            <label class="lblProductPricesHeader">Variation</label>
          </th>
          <th scope="col" class="thProductPricesHeader">
            <label class="lblProductPricesHeader">Graphique</label>
          </th>
        </tr>
      </thead>
      <tbody class="table_product_price_euronext_summary_body" id="table_product_price_euronext_summary_body_{{productCode}}">
        <ng-container #productPricesLinesEuronext_Placeholder></ng-container>
      </tbody>
    </table>
  </div>
</div>
