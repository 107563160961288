import { Component, Injectable, OnInit } from '@angular/core';
import { User } from '../../../../tools/User';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

@Injectable()
export class FooterComponent implements OnInit {

  constructor() { };

  ngOnInit(): void {    
  }

  GetIsConnected(): boolean {
    return User.GlobalFunctionsUser.GetIsConnected();
  }
}
