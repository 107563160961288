import { Component, Injectable, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-expert-opinion-header',
  templateUrl: './expert_opinion_header.component.html',
  styleUrls: ['./expert_opinion_header.component.scss']
})

@Injectable()
export class ExpertOpinionHeaderComponent implements OnInit {
  urlImage: string = '';
  
  @Input() expertOpinionHeaderProductId: number;
  @Input() expertOpinionHeaderStrategyId: number;
  @Input() expertOpinionHeaderStrategyTitle: string = "Opinion"; 

  constructor() {

  }

  ngOnInit(): void {
    this.urlImage = 'assets/images/Background_' + this.expertOpinionHeaderProductId + '.png';
    if(this.expertOpinionHeaderProductId != 9){
      this.expertOpinionHeaderStrategyTitle = "Opinion"; 
    }
  }
}
