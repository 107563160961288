import { Component, EventEmitter, Injectable, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { GraphOptions } from 'src/model/GraphOptions';


@Component({
  selector: 'interval_selector',
  templateUrl: './interval_selector.component.html',
  styleUrls: ['./interval_selector.component.scss']
})

@Injectable()
export class IntervalSelectorComponent implements OnInit {
  defaultInterval: string = '1m';

  @Output() currentInterval: string;
  @Output() eventRefreshGraph = new EventEmitter();
  @Output() eventExportGraph = new EventEmitter();

  defaultCssClassInterval: string = 'lblRefreshGraphInterval';
  cssClassIntervalSelected: string = 'lblRefreshGraphIntervalSelected';
  cssClassIntervalFirst: string = 'lblRefreshGraphIntervalFirst';

  @Input() unit: string;
  @Input() imgLinearSrc: string;
  @Input() imgCandlestickSrc: string;
  @Input() isCandlestick: boolean;
  @Input() pricesType: string = '';
  cssClassImgLinear: string = 'imgLinear imgLinearOrCandlestickSelected';
  cssClassImgCandlestick: string = 'imgCandlestick';

  cssClass1w: string = this.defaultCssClassInterval + " " + this.cssClassIntervalFirst;
  cssClass1m: string = this.defaultCssClassInterval + " " + this.cssClassIntervalSelected;
  cssClass3m: string = this.defaultCssClassInterval;
  cssClass6m: string = this.defaultCssClassInterval;
  cssClassMax: string = this.defaultCssClassInterval;

  @Input() events: Observable<void>;

  constructor() {
  }

  ngOnInit() {
    this.imgLinearSrc = "../../../../../../assets/images/linear_on.png";
    this.imgCandlestickSrc = "../../../../../../assets/images/candlestick_on.png";
    this.isCandlestick = false;
  }

  ExportGraph() {
    this.eventExportGraph.emit();
  }

  ToggleGraphType(isCandleStick: boolean) {
    this.cssClassImgLinear = 'imgLinear' + ((isCandleStick) ? "" : " imgLinearOrCandlestickSelected");
    this.cssClassImgCandlestick = 'imgCandlestick' + ((isCandleStick) ? " imgLinearOrCandlestickSelected" : "");

    this.isCandlestick = isCandleStick

    if (this.currentInterval == '' || !this.currentInterval)
      this.currentInterval = this.defaultInterval;

    let graphOptions: GraphOptions = new GraphOptions();
    graphOptions.interval = this.currentInterval;
    graphOptions.isCandleStick = this.isCandlestick;
    graphOptions.isFirstLoad = false;
    graphOptions.YAxisText = this.unit;

    this.RefreshGraph(graphOptions);
  }

  RefreshGraph(graphOptions: GraphOptions) {
    this.eventRefreshGraph.emit(graphOptions);
  }

  RefreshIntervalStyle(interval: string) {
    this.currentInterval = interval;

    let graphOptions: GraphOptions = new GraphOptions();
    graphOptions.interval = this.currentInterval;
    graphOptions.isCandleStick = this.isCandlestick;
    graphOptions.isFirstLoad = false;
    graphOptions.YAxisText = this.unit;

    this.eventRefreshGraph.emit(graphOptions);

    this.cssClass1w = this.defaultCssClassInterval + " " + this.cssClassIntervalFirst;
    this.cssClass1m = this.defaultCssClassInterval;
    this.cssClass3m = this.defaultCssClassInterval;
    this.cssClass6m = this.defaultCssClassInterval;
    this.cssClassMax = this.defaultCssClassInterval;

    switch (graphOptions.interval) {
      case "1w":
        this.cssClass1w += " " + this.cssClassIntervalSelected;
        break;
      case "1m":
        this.cssClass1m += " " + this.cssClassIntervalSelected;
        break;
      case "3m":
        this.cssClass3m += " " + this.cssClassIntervalSelected;
        break;
      case "6m":
        this.cssClass6m += " " + this.cssClassIntervalSelected;
        break;
      case "max":
        this.cssClassMax += " " + this.cssClassIntervalSelected;
        break;
    }
  }
}
