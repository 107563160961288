import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReportComponent } from './account/report-components/report/report.component';
import { QuotesPageComponent } from './quotes/quotes_page.component';
import { AnalysisComponent } from './analysis/analysis/analysis.component';
import { MyAccountComponent } from './account/my_account/my_account.component';
import { loginWithTeaserComponent } from './account/login_with_teaser/login_with_teaser.component';
import { PasswordForgottenWithTeaserComponent } from './account/password_forgotten_with_teaser/password_forgotten_with_teaser.component';
import { AlertComponent } from './account/alert-components/alert/alert.component';
import { authGuard } from './shared/guard/authenticated.guard';
import { FertilizerComponent } from './analysis/crops/fertilizer/fertilizer.component';
import { CornComponent } from './analysis/crops/corn/corn.component';
import { RapeComponent } from './analysis/crops/rape/rape.component';
import { SunflowerComponent } from './analysis/crops/sunflower/sunflower.component';
import { CommonWheatComponent } from './analysis/crops/common-wheat/common-wheat.component';
import { DurumWheatComponent } from './analysis/crops/durum-wheat/durum-wheat.component';
import { FeedBarleyComponent } from './analysis/crops/feed-barley/feed-barley.component';
import { MaltBarleyComponent } from './analysis/crops/malt-barley/malt-barley.component';
import { ReplayComponent } from './replay/replay.component';
import { MinuteChronoComponent } from './replay/minute-chrono/minute-chrono.component';
import { CompanyFarmersComponent } from './replay/company-farmers/company-farmers.component';
import { MarketPanoramicComponent } from './replay/market-panoramic/market-panoramic.component';
import { subscriptionGuard } from './shared/guard/subscription.guard';
import { AgritelOverlayPlusComponent } from './agritel-overlay-plus/agritel-overlay-plus.component';
import { ExpiredPlanPopinComponent } from './account/expired_plan_popin/expired_plan_popin.component';
import { subscriptionExpiryGuard } from './shared/interceptors/subscription/subscription-expiry.guard';
import { FaqComponent } from './faq/faq.component';
import { BaseComponent } from './base/base.component';
import { EditoComponent } from './edito/edito.component';


const routes: Routes = [
  { path: "connexion", title: "", component: loginWithTeaserComponent },
  {
    path: '', title: "", component: BaseComponent, canActivateChild: [authGuard, subscriptionExpiryGuard], children: [
      { path: '', redirectTo: '/cotations', pathMatch: 'full' },
      { path: 'cotations', title: "Cotations", component: QuotesPageComponent, },
      {
        path: 'strategies', title: "Stratégies", component: AnalysisComponent, children:
          [
            { path: '', redirectTo: 'ble/tendre', pathMatch: 'full' },
            { path: 'engrais', title: "Engrais", component: FertilizerComponent },
            { path: 'mais', title: "Mais", component: CornComponent },
            { path: 'colza', title: "Colza", component: RapeComponent },
            { path: 'tournesol', title: "Tournesol", component: SunflowerComponent },
            { path: 'ble/tendre', title: "Blé tendre", component: CommonWheatComponent },
            { path: 'ble/dur', title: "Blé dur", component: DurumWheatComponent },
            { path: 'orge/fourragere', title: "Orge fouragère", component: FeedBarleyComponent },
            { path: 'orge/brassicole/printaniere', title: "Orge brassicole printanière", component: MaltBarleyComponent },
          ]
      },
      { path: 'quotidienne', title: "Quotidienne", component: EditoComponent },
      { path: 'aide', title: "Aide", component: FaqComponent },
      {
        path: 'compte', canActivateChild: [subscriptionExpiryGuard],
        children: [
          { path: 'informations', title: "Informations", component: MyAccountComponent },
          { path: 'rapports', title: "Rapports", component: ReportComponent },
          { path: 'alertes', title: "Alertes", component: AlertComponent },
        ]
      },
      {
        path: 'replay', title: "Replay Vidéo", component: ReplayComponent, canActivateChild: [subscriptionExpiryGuard], children: [
          { path: '', redirectTo: '3-min-chrono', pathMatch: 'full' },
          { path: '3-min-chrono', title: '3 Min Chrono', component: MinuteChronoComponent },
          { path: 'compagnie-farmers', title: 'La compagnie des farmers', component: CompanyFarmersComponent },
          { path: 'panoramas-marche', title: 'Panoramas de Marché', component: MarketPanoramicComponent, canActivate: [subscriptionGuard] },

        ]
      },
    ]
  },

  { path: 'agritel-plus', title: "Agritel Farmer Plus", component: AgritelOverlayPlusComponent },
  { path: 'expire', component: ExpiredPlanPopinComponent },
  { path: "mot-de-passe", component: PasswordForgottenWithTeaserComponent },
  { path: '', redirectTo: '/cotations', pathMatch: 'full' },
  { path: '**', redirectTo: '/cotations' },
]

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
