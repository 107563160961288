import { Injectable } from '@angular/core';
import { Service } from '../service';
import { Observable } from 'rxjs';
import { Banner } from 'src/model/banner';

@Injectable({
  providedIn: 'root'
})
export class BannerService extends Service {

  override controller = 'Message';

  getBannerMessage(code: string) {
    let data: Observable<Banner> = this.HttpPostGeneric(this.controller, "GetMessageFromCode", JSON.stringify(code));

    JSON.parse(JSON.stringify(data)); 

    return data;
  }




}
