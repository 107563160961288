<div class="component-container">

  <div class="scrollY {{classBlurred}} ">
    <app-expert-opinion-header [expertOpinionHeaderProductId]="sentProductId"
      [expertOpinionHeaderStrategyTitle]="strategyTitle"></app-expert-opinion-header>
    <app-expert-opinion [productId]="sentProductId" [expertOpinionSpecificProductId]="1"></app-expert-opinion>
    <app-expert-opinion-header [expertOpinionHeaderProductId]="sentProductId"
      [expertOpinionHeaderStrategyTitle]="secondaryStrategyTitle"></app-expert-opinion-header>
    <app-expert-opinion [productId]="sentProductId" [expertOpinionSpecificProductId]="2"></app-expert-opinion>
    <app-focus [productId]="sentProductId"></app-focus>
    <app-opinion [productId]="sentProductId"></app-opinion>
  </div>

</div>