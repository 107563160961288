import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/shared/components/notifications/notification.service';
import { HeaderService } from 'src/app/services/header/header.component.service';
import { AlertsHistoryByUser } from 'src/model/alerts/AlertsHistoryByUser';
import { AlertHistoryService } from 'src/app/services/http/alerts/alert.history.service';
import { LeftMenuService } from 'src/app/services/left-menu.component.service';
import { StringTools } from 'src/tools/StringTools';
import { HttpContextToken } from '@angular/common/http';
import { GlobalParams } from 'src/global';



@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  constructor(private headerService: HeaderService, private notificationService: NotificationService, private alertHistoryService: AlertHistoryService, private leftMenuService: LeftMenuService) { }

  alertsHistoryReceived: AlertsHistoryByUser[] = [];
  notificationNotRead: number = 0;

  linkName: string = 'Cliquez ici !';
  alreadyMarkedReadForAll: boolean = false;

  ngOnInit(): void {
    this.setHeaderTitle();
    this.getAlertLogs();
  }

  setHeaderTitle() {
    this.headerService.setTitle("Alertes et notifications");

  }

  getAlertLogs() {

    this.alertHistoryService.GetAlertHistoryForUser().subscribe({
      next: (item: AlertsHistoryByUser[]) => {
        item.forEach((element) => {
          element.link = StringTools.clickableLinkIfExist(element.message);
          element.message = StringTools.truncateLinkFromText(element.message);
        })
        this.alertsHistoryReceived = item;
      },
      error: (err: { status: number; }) => {
        if (err.status != 401) {
          this.notificationService.ShowError("Erreur", "Erreur lors des chargements de vos préferences")
        }
      },
      complete: () => {
        console.info('Information loaded');
      }
    })
    this.leftMenuService.notification.subscribe(item => this.notificationNotRead = item);
  }
  /**
    * Detect the mouse event in a specific area. 
    * The function is only binding to elements where the isRead is false. It's dynamically binded. 
    * @param notificationId pass the ID of the current alert where the mouse is.
    * @param isRead know the state of the notification.
    * @returns array of number that correspond to the alertID read  
    */
  onMouseEnter(notificationId: number, isRead: boolean) {

    if (isRead === false) {
      // Loop through the object received from the backend and mapped on the template
      this.alertsHistoryReceived.forEach((element) => {
        // To make sure I modify the correct alert before modifying the current object mapped on the template and sending to the backend. 
        if (element.alertsSendedId === notificationId) {
          // Call the function that sends to the backend the alertID when the mouse event enters a specific area. 
          this.updateIfNotificationRead(notificationId);
          // Set the new state of the object item (use in the template) to true. 
          element.isRead = true;
        }
      })
    }
  }

  /**
    * Sends to the backend the ID of the current notification where the mouse is. 
    * @param notificationId pass the ID of the current alert where the mouse is.
    * @returns void  
    */
  updateIfNotificationRead(notificationId: number): void {
    // To disable the spinner when updating the state of the notification 
    GlobalParams.BYPASS_SPINNER = new HttpContextToken(() => true);

    this.alertHistoryService.UpdateAlertIfRead(notificationId).subscribe({
      error: (err: { status: number; }) => {
        if (err.status != 401) {
          this.notificationService.ShowError("Erreur", "Erreur lors des chargements de vos préferences")
        }
      },
      complete: () => {
        this.leftMenuService.setNumberOfNotification(this.notificationNotRead.valueOf() - 1);
      }
    })
  }

  markAllRead() {

    if(this.alreadyMarkedReadForAll === true ){
      this.notificationService.ShowInfo("", "Vos alertes sont déjà marquées comme lues.")
    }else {
      this.alertHistoryService.UpdateAllAlertToRead().subscribe({
        error: (err: { status: number; }) => {
          if (err.status != 401) {
            this.notificationService.ShowError("Erreur", "Erreur lors des chargements de vos préferences");
          }
        },
        complete: () => {
          this.alertsHistoryReceived.forEach((item) => item.isRead = true);
          this.leftMenuService.setNumberOfNotification(0);
          this.alreadyMarkedReadForAll = true; 
        }
      })
    }
  }
}
