export namespace Dates {
  export class GlobalFunctionsDates {

    public static GetMostRecentDate(jsonQuotesService) {
      let returnValue: Date = new Date(Math.max.apply(null, jsonQuotesService.map(e => {
        return new Date(e.date);
      })));

      return returnValue;
    }

    public static AddDays(date: Date, daysToAdd: number): Date {
      var date = new Date();
      date.setDate(date.getDate() + daysToAdd);

      return date;
    }

    public static GetTimezoneOffset() {
      var offset = new Date().getTimezoneOffset();
      var sign = offset < 0 ? '+' : '-';
      offset = Math.abs(offset);

      return sign + offset / 60;
    }

    public static GetMonthShortName(monthNo) {
      const date = new Date();
      date.setMonth(monthNo - 1);

      return date.toLocaleString(navigator.language, { month: 'short' });
    }

  }
}
