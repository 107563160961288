import { Service } from "../service";
import { Injectable } from "@angular/core";
import { AlertSubscriptionUser } from "src/model/alerts/AlertSubscriptionUser";


@Injectable({
  providedIn: 'root'
})

export class AlertService extends Service {

  subscriptionAlertController: string = 'AlertsSubscriptionUser';
  getAllSubscriptionsAlertsForUser: string = 'GetAllSubscriptionsAlertsForUser';
  saveSubscriptionAlertUser: string = 'SaveSubscriptionAlertUser';

  /**
   * Function to get all subscripted alert and mode of alert for a user 
   * @returns 
   */

  GetAllSubscriptionsAlertsForUser(): any {
    let data: any = this.HttpPostGeneric(this.subscriptionAlertController, this.getAllSubscriptionsAlertsForUser, null)
    JSON.parse(JSON.stringify(data));
    return data;

  }

  SaveSubscriptionAlertForUser(SaveSubscriptionAlertUserRequest: AlertSubscriptionUser[]): any {

    let data: any = this.HttpPostGeneric(this.subscriptionAlertController, this.saveSubscriptionAlertUser, SaveSubscriptionAlertUserRequest)
    JSON.parse(JSON.stringify(data));
    return data;

  }



}