import { Component , Output} from '@angular/core';

@Component({
  selector: 'app-malt-barley',
  templateUrl: './malt-barley.component.html',
  styleUrls: ['./malt-barley.component.scss']
})
// Orge brassicole 
export class MaltBarleyComponent {
  @Output() sentProductId: number = 5;

  classBlurred: any;
}
