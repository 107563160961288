import { Injectable } from "@angular/core";
import { Service } from "./service";
import { Observable } from "rxjs";
import { RequestService } from "src/model/edito/RequestService";
import { Edito } from "src/model/edito/edito";

@Injectable({
  providedIn: 'root'
})

export class EditoService extends Service {



  AllDataMarket(requestService: RequestService<Array<Edito>>) {

    let data: Observable<RequestService<Array<Edito>>> = this.HttpPostApiAgritel("Market", "LastestDataMarketAvailable", requestService);

    JSON.parse(JSON.stringify(data));

    return data;

  }

  HistoryDataMarket(requestService: RequestService<string>) {

    let data: Observable<RequestService<Array<Edito>>> = this.HttpPostApiAgritel("Market", "HistoryDataMarket", requestService);

    JSON.parse(JSON.stringify(data));

    return data;

  }
}