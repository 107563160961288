<div class="minute-form-container">
  <div class="header-container">
    <h5>Poser votre question pour le prochain 3 min chrono ? </h5>
  </div>
  <div class="explaination-container">
    <img src="../../assets/images/svg/info-icon.svg" alt="information icon" width="40" class="info-icon">
    <p>
      Chaque jeudi, découvrez nos vidéos
      <a routerLink="../replay/3-min-chrono" class="minute-chrono"><b>Le 3 min Chrono</b></a>.
      Ces courtes vidéos vous apportent des réponses rapides et pertinentes à vos questions sur le marché des grains.
    </p>
  </div>
  <div class="form-container">
    <form [formGroup]="askForm" (ngSubmit)="onClick()">
      <div *ngIf="askForm.invalid && (askForm.dirty || askForm.touched)" class="alert alert-danger">
        <div *ngIf="askForm.hasError('required')">
          Name is required.
        </div>
        <div *ngIf="askForm.hasError('minlength')">
          Name must be at least 4 characters long.
        </div>
      </div>
      <mat-form-field hintLabel="Max 200 characters" appearance="outline" class="mat-hint full-width">
        <mat-label>Ecrire ma question</mat-label>

        <textarea matInput #input [maxLength]="200" required="true" cdkTextareaAutosize formControlName="question"
          id="question"></textarea>
        <mat-hint align="end" class="mat-hint">{{input.value.length}}/200</mat-hint>
      </mat-form-field>
      <button type="submit" class="buttonFullTextBold"> Envoyez</button>

    </form>

  </div>
</div>