export class Crop {
  bleTendre: boolean = false;
  mais: boolean = false;
  colza: boolean = false;
  ourgeFourragere: boolean = false;
  orgeBrassPrin: boolean = false;
  orgeBrassHiv:boolean = false;
  tournesol: boolean = false;
  bleDur: boolean = false;
  engrais: boolean = false

}