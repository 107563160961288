<div class="base-container">
  <header class="header">
    <app-header></app-header>
  </header>
  <div class="left-menu">
    <leftMenu></leftMenu>
  </div>
  <div class="component-container">
    <div class="centered-container">
      <router-outlet></router-outlet>
    </div>
      
    <app-agritel-plus></app-agritel-plus>
  </div>
  <footer class="footer">
    <app-footer></app-footer>
  </footer>
</div>