<div class="expert-opinion-container">
  <div class="expert-opinion-header">
    <img height="40" width="40" src="../../../../assets/images/icon-analysis/expert-opinion.png" />

    <p class="expert-opinion-label">Avis de l'expert </p>
  </div>
  <div class="expert-opinion-content-container">
    <div class="title-text-container">
      <div class="expert-opinion-article-name">
        {{expertOpinion.title}}
      </div>
      <div class="expert-opinion-background">
        <div class="expert-opinion-text-container">
          <div [innerHTML]="expertOpinion.text | safeHtml">
          </div>
        </div>
      </div>
    </div>
  
  </div>
</div>