<div>
  <div class="decrypt-container">
    <div class="decrypt-title-header">
      <img src="assets/images/decrypt_icon.png" width="50" />
      <label class="decrypt-label">Décryptage </label>
    </div>
    <div class="decrypt-content-container" >
      <div class="decrypt-article-name">
         {{title}}
      </div>
      <div class="decrypt-detail-text" [innerHTML]="text | safeHtml"></div>
    </div>
  </div>
</div>
