import { Component, InjectionToken, OnInit } from '@angular/core';
import { EditoService } from '../services/http/edito.service';
import { NotificationService } from '../shared/components/notifications/notification.service';
import { RequestService } from 'src/model/edito/RequestService';
import { HeaderService } from '../services/header/header.component.service';
import { Edito } from 'src/model/edito/edito';
import { filter, mergeMap } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalParams } from 'src/global';
import { BreakpointObserver } from '@angular/cdk/layout';
import { DateAdapter } from '@angular/material/core';


@Component({
  selector: 'app-edito',
  templateUrl: './edito.component.html',
  styleUrls: ['./edito.component.scss']
})
export class EditoComponent implements OnInit {
  constructor(private editoService: EditoService, private notificationService: NotificationService, private headerService: HeaderService, private breakpointObserver: BreakpointObserver, private dateAdapter: DateAdapter<any>) { }

  edito: Array<Edito> = [];
  isMobile: boolean = false;

  requestService: RequestService<Array<Edito>> = {
    token: GlobalParams.AgritelAPIToken,
    error: "",
    isNotErr: true,
    data: [],
  }

  requestServiceDate: RequestService<string> = {
    token: GlobalParams.AgritelAPIToken,
    error: "",
    isNotErr: true,
    data: null,
  }
  dateSelectedEdito = new FormGroup({
    date: new FormControl(new Date(), Validators.required, )
  });

  currentDate = new Date(); 
  currentYear = new Date().getFullYear();
  minDate = new Date(this.currentYear - 0, this.currentDate.getMonth() - 2, this.currentDate.getDate());
  maxDate = new Date(this.currentYear + 0, this.currentDate.getMonth(), this.currentDate.getDate());
  


  ngOnInit(): void {
    this.getAllMarketData();
    this.setHeaderTitle();
    this.changeFormatDatePicker();
    this.dateAdapter.setLocale("fr");
  }

  setHeaderTitle() {
    this.headerService.setTitle("Quotidienne");

  }

  getAllMarketData() {
    this.editoService.AllDataMarket(this.requestService)
      .pipe(
        mergeMap((item: RequestService<Array<Edito>>) => item.data),
        filter(edito => edito.currentImg != "marketbs"),
        filter(edito => edito.languageId === "FR"),
        filter(edito => new Date(edito.dateMAJ).getDate() === new Date().getDate())

      )
      .subscribe({
        next: (value) => {
          this.edito.push(value);
        },
        error: (err) => {
          if (err != 401) {
            this.notificationService.ShowError("Error lors des chargements des données", "");
          }
        },
        complete: () => { }
      })
  }

  changeFormatDatePicker() {
    const isSmallScreen = this.breakpointObserver.isMatched('(max-width: 768px)');
    if (isSmallScreen) {
      this.isMobile = true;
    }
    else {
      this.isMobile = false;
    }
  }

  onSubmit() {
    // Empty the array before submiting the form to delete the data that we have fetch in getAllMarketData() to push the new values for the day we want. 
    this.edito = [];

    this.requestServiceDate.data = this.formatStringFromDate(this.dateSelectedEdito.value.date);

    this.editoService.HistoryDataMarket(this.requestServiceDate).pipe(
      mergeMap((item: RequestService<Array<Edito>>) => item.data),
      filter(edito => edito.currentImg != "marketbs"),
      filter(edito => edito.languageId === "FR")

    ).subscribe({
      next: (value) => {
        this.edito.push(value);
      },
      error: (err) => {
        if (err != 401) {
          // this.notificationService.ShowError("Error lors des chargements des données", "");
          console.log(err);
        }
      },
      complete: () => { }
    })
  }

  filterWeekendOnCalendar = (d: Date | null): boolean => {
    const day = new Date(d).getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  };


  formatStringFromDate(unformatedDate: Date): string {
    const date: Date = new Date(unformatedDate);
    // + 1 for the month it's because it's starts at 0 like an index of array and it's not what we want in our case. 
    const dateBuilder: string = date.getFullYear().toString() + "-" + (date.getMonth() + 1).toString() + "-" + date.getDate().toString();

    return dateBuilder;
  }
}