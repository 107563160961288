import { Component, Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'legal_mentions',
  templateUrl: './legal_mentions.component.html',
  styleUrls: ['./legal_mentions.component.scss']
})

@Injectable()
export class LegalMentionsComponent implements OnInit {
  title = 'Agritel Farmer Legal mentions';

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  GetCurrentYear(): number {
    return new Date().getFullYear();
  }

  showInfoFooter(): boolean {
    if (this.router.url != "/connexion") {
      return true;
    } else {
      return false;
    }
  }
}
