import { Component, Injectable, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderService } from 'src/app/services/header/header.component.service';
import { Connection } from '../../../tools/Connection';
import { MatMenuTrigger } from '@angular/material/menu';
import { User } from 'src/tools/User';

@Component({
  selector: 'user-menu',
  templateUrl: './user_menu.component.html',
  styleUrls: ['./user_menu.component.scss']
})

@Injectable()
export class UserMenuComponent {
@ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  constructor(private router : Router, private headerService : HeaderService) {
  }


  Logout(): void {
    Connection.GlobalFunctionsConnection.Logout();
    this.headerService.setTitle('');
    this.router.navigateByUrl('/connexion'); 
  }


  someMethod() {
    this.trigger.openMenu();
  }

  GetName(): string {
    return User.GlobalFunctionsUser.GetFirstName();
  }

}
