<div class="container-change-password">
    <div class="container-title">
      <h5>Changement de mot de passe</h5>
  </div>
  <div class="container-form">
    <form class="" id="changepassword" name="changepassword-form">

      <div class="change-password-div">
        <div class="">
          <mat-form-field appearance="outline" id="mat-form-field-CurrentPassword" class="width_75">
            <mat-label>Mot de passe actuel</mat-label>
            <input matInput cdkFocusInitial [type]="hidePassword ? 'password' : 'text'" id="CurrentPassword"
              [(ngModel)]="CurrentPassword" required="required" name="CurrentPassword"
              placeholder="Mot de passe actuel*" autocomplete="current-password">
            <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hidePassword" class="buttonTogglePassword" type="button">
              <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field appearance="outline" id="mat-form-field-NewPassword" class="width_75">
            <mat-label>Nouveau mot de passe </mat-label>
            <input matInput [type]="hideNewpassword ? 'password' : 'text'" id="NewPassword" [(ngModel)]="NewPassword"
              required="required" name="NewPassword" autocomplete="new-password" placeholder="Nouveau mot de passe *"
              value="">
            <button mat-icon-button matSuffix (click)="hideNewpassword = !hideNewpassword"
              [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideNewpassword" class="buttonTogglePassword"
              type="button">
              <mat-icon>{{hideNewpassword ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field  appearance="outline" id="mat-form-field-NewPasswordConfirm" class="width_75">
            <mat-label>Confirmation nouveau mot de passe</mat-label>
            <input matInput [type]="hideNewPasswordConfirm ? 'password' : 'text'" id="NewPasswordConfirm"
              [(ngModel)]="NewPasswordConfirm" required="required" name="NewPasswordConfirm"
              placeholder="Confirmation nouveau mot de passe *" autocomplete="new-password">
            <button mat-icon-button matSuffix (click)="hideNewPasswordConfirm = !hideNewPasswordConfirm"
              [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideNewPasswordConfirm"
              class="buttonTogglePassword" type="button">
              <mat-icon color="black">{{hideNewPasswordConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <hr>
        <div class="textCenter form-controls">
          <button type="submit" class=" buttonOutlinedTextBoldSmallBlack width_75"
            (click)="ChangePassword();">Sauvegarder</button>
        </div>
      </div>
    </form>
  </div>
</div>