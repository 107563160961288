import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/http/user.service';
import { NotificationService } from 'src/app/shared/components/notifications/notification.service';

@Component({
  selector: 'app-replay-nav',
  templateUrl: './replay-nav.component.html',
  styleUrls: ['./replay-nav.component.scss']
})
export class ReplayNavComponent implements OnInit {

  constructor(private userService : UserService, private notificationService :NotificationService){}
  toShow : string = "none"; 

  ngOnInit(): void {
    this.userSubscriptedPlan(); 
  }

  userSubscriptedPlan(){
    this.userService.GetIdProfil().subscribe({
      next: (value) => {
        switch (value.idProfil) {
          case 1:
            this.toShow =  "inline";
            break; 
          case 2:
          case 3:
          case 4:
            this.toShow =  "none";
            break; 
        }
      },
      error: (err) => {
        if (err.status !== 401) {
          this.notificationService.ShowError("Erreur lors du chargement des informations.", "");
        }
      },
      complete: () => {
        console.info("information received");
      },
    })
  }
}
