export class Company {
  id: number = 0;
  name: string = '';
  address1: string = '';
  address2: string = '';
  zipCode: string = '';
  city: string = '';
  country: string = '';
  phone: string = '';
  TVANum: string = '';
  createdAt: Date = new Date();
  updatedAt: Date = new Date();
  rightLevel: number = 0;
  rightBegin: Date = new Date();
  rightEnd: Date = new Date();
  alerts: number = 0;
  alertMode: string = '';
  products: number = 0;
  surface: number = 0;
  tempLevel: number = 0;
  isModeSecure: boolean = false;
  stripeId: string = '';
  provenance: string = '';
  sageCodeTiers: string = '';
  firstConnection: boolean = false;
  checkoutSessionId: string = '';
}
