import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { HeaderService } from 'src/app/services/header/header.component.service';
import { GlobalFunctionsToken } from '../../../tools/Token';
import { User } from '../../../tools/User';
import { GlobalParams } from 'src/global';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router, private headerService : HeaderService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string = GlobalFunctionsToken.GetConnectionToken();
    if (token) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          'Token': `${token}`,
          'Audience':`${GlobalParams.Audience}`
        }
      });
    }
    return next.handle(request)
      .pipe(
        tap({
          error:(err) => {
            if(err.status === 401){
              GlobalFunctionsToken.DeleteConnectionToken();
              User.GlobalFunctionsUser.SetIsConnected(false);
              this.headerService.setTitle(''); 
              this.router.navigateByUrl('/connexion'); 
            } 
          }
        }), 
        finalize(() =>{})
      )
  }
}
