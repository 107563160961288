
export namespace Graph {
  export class GlobalFunctionsGraph {
    public static GetStartDateForInterval(interval: string) {
      let startDate: Date;

      switch (interval) {
        case '1w':
          startDate = new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() - 7
          );
          break;
        case '1m':
          startDate = new Date(
            new Date().getFullYear(),
            new Date().getMonth() - 1,
            new Date().getDate()
          );
          break;
        case '3m':
          startDate = new Date(
            new Date().getFullYear(),
            new Date().getMonth() - 3,
            new Date().getDate()
          );
          break;
        case '6m':
          startDate = new Date(
            new Date().getFullYear(),
            new Date().getMonth() - 6,
            new Date().getDate()
          );
          break;
      }

      return startDate;
    }
  }
}
