import { Component, Injectable, OnInit } from '@angular/core';


@Component({
  selector: 'opinionAndStrategy',
  templateUrl: './opinion_and_strategy.component.html',
  styleUrls: ['./opinion_and_strategy.component.scss']
})

@Injectable()
export class OpinionAndStrategyComponent implements OnInit {

  errorMessage = '';
  productId: number;
  constructor() {
  }

  ngOnInit(): void {
  }

}
