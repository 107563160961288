import { GlobalParams } from "../global";

export namespace Cookie {
  export class GlobalFunctionsCookie {
    public static SetCookie(cName, cValue, expMinutes) {
      let cookie: string = this.GetCookie(cName);

      if (cookie == null || cookie == "") {
        let date = new Date();
        date.setTime(date.getTime() + (expMinutes * 60 * 1000));
        const expires = "expires=" + date.toUTCString();
        document.cookie = cName + "=" + cValue + "; " + expires + "; path=/" + GlobalParams.CookiePath;
      }
    }

    public static GetCookie(cName) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${cName}=`);

      if (parts.length === 2) {
        return parts.pop().split(';').shift();
      }

      return "";
    }

    public static DeleteCookie(name: string) {
      document.cookie = name + '=;Expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/';
    }
  }
}
