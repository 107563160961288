import { HttpContextToken } from '@angular/common/http';
import { Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, Injectable, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as $ from 'jquery/dist/jquery.min.js';
import { GlobalFunctions, GlobalParams } from '../../../../global';
import { Dates } from '../../../../tools/Date';
import { QuotesService } from '../../../services/http/quotes.service';
import { PriceChartFuturesComponent } from '../charts/price_chart/price_chart_futures/price_chart_futures.component';
import { ProductTableEuronextComponent } from '../product_table/product_table_euronext/product_table_euronext.component';
import { QuotesComponent } from '../quotes_component.component';

@Component({
  selector: 'euronext_quotes',
  templateUrl: './euronext_quotes.component.html',
  styleUrls: ['./euronext_quotes.component.scss']
})

@Injectable()
export class EuronextQuotesComponent extends QuotesComponent implements OnInit {
  animationSpeed = 5;
  isTestMode: boolean = false;
  isDelayBeforeNewRefresh: boolean = false;

  constructor(public dialog: MatDialog, private resolver: ComponentFactoryResolver, private quotesService: QuotesService) {
    super();
  }

  @ViewChild('productTablesEuronext_Placeholder', { read: ViewContainerRef })
  public productTablesEuronext_Placeholder: ViewContainerRef;

  AllProductTablesEuronext_Placeholder: Array<ComponentRef<ProductTableEuronextComponent>> = [];

  override ngOnInit(): void {
    this.tableTitle = "Futures - Euronext";
    this.showDropDownNbLines = true;
    this.showRefreshButton = true;
    this.tableType = "Euronext";
  }

  RefreshDateUpdate(dateTimeRefreshData: Date, showTime: boolean) {
    var dateWithoutTime: string = dateTimeRefreshData.toLocaleString("fr-FR").split(' ')[0];

    if (showTime) {
      let time: string = dateTimeRefreshData.toLocaleTimeString("fr-FR", { hour: '2-digit', minute: '2-digit' });
      this.dateLastUpdate = dateWithoutTime + " " + time;
    }
    else
      this.dateLastUpdate = dateWithoutTime;

    this.dateLastUpdate += " (GMT" + Dates.GlobalFunctionsDates.GetTimezoneOffset() + ")";
  }

  RefreshFuturesEuronext() {
    if (this.isDelayBeforeNewRefresh)
      return;

    this.isDelayBeforeNewRefresh = true;

    $("#btRefreshFuturesEuronext").attr("style", "height:20px;cursor:default;");
    $("#lbRefreshFuturesEuronext").attr("style", "cursor:default;margin-bottom:0 !important;padding-left:5px;padding-right:15px;color:#dddddd");

    setTimeout(() => {
      this.isDelayBeforeNewRefresh = false;
      $("#btRefreshFuturesEuronext").attr("style", "height:20px;cursor:pointer;");
      $("#lbRefreshFuturesEuronext").attr("style", "cursor:pointer;margin-bottom:0 !important;padding-left:5px;padding-right:15px;");
    }, 2000);

    this.LoadData(true);
  }

  GetTooltipForProduct(productCode: string) {
    let tooltip: string = '';

    switch (productCode) {
      case "EBM":
        tooltip = "Contrat à terme \"blé de meunier n°2\"</br>Sous-jacent : blé d'origine \"Union Européenne\"</br></br>" +
          "Critères qualité:</br>" +
          "- Indice de temps de chute de Hagberg 220 secondes</br>" +
          "- Teneur en protéines 11 %</br>" +
          "- Poids spécifique 76 kg/h</br>" +
          "- Humidité : 15%</br>" +
          "- Grains brisés: 4%</br>" +
          "- Impuretés : 2%</br>" +
          "<a href=\"https://live.euronext.com/fr/product/commodities-futures/EBM-DPAR/contract-specification\" style=\"color:blue\" target=\"_blank\">Lien vers l'ensemble des spécifications</a>";
        break;
      case "ECO":
        tooltip = "Contrat à terme  \"Colza\"</br>Sous-jacent  : \"Colza toutes origines\" de variété 00.</br></br>" +
          "Critères qualité:</br>" +
          "- Taux d'huile: 40%</br>" +
          "- Humidité: 9%</br>" +
          "- Taux d'impuretés: 2%</br>" +
          "<a href=\"https://live.euronext.com/fr/product/commodities-futures/ECO-DPAR/contract-specification\" style=\"color:blue\" target=\"_blank\">Lien vers l'ensemble des spécifications</a>";
        break;
      case "EMA":
        tooltip = "Contrat à terme \"Maïs\"</br>Sous-jacent : maïs d'origine \"Union Européenne\", jaune, roux.</br></br>" +
          "Critères qualité:</br>" +
          "Humidité: 15%</br>" +
          "Grains brisés: 5%</br>" +
          "Impuretés totales: 3.5%</br>" +
          "<a href=\"https://live.euronext.com/fr/product/commodities-futures/EMA-DPAR/contract-specification\" style=\"color:blue\" target=\"_blank\">Lien vers l'ensemble des spécifications</a>";
        break;
    }

    return tooltip;
  }

  HideLoader() {
    $(".divEuronextQuotes").animate({ opacity: 1 }, 500);
    $("#loaderEuronextQuotes").hide();
  }

  override LoadData(isRefresh: boolean) {
    GlobalParams.BYPASS_SPINNER = new HttpContextToken(() => true);

    if (isRefresh) {
      var top: number = $(".divEuronextQuotes").height() / 2;

      $("#loaderEuronextQuotes").css("top", top + "px");
      $(".divEuronextQuotes").animate({ opacity: 0.4 }, 500);
      $("#loaderEuronextQuotes").show();

      setTimeout(this.HideLoader, 1000);
    }

    this.quotesService.GetProductQuotes(0).subscribe((data) => {
      const jsonGetProductQuotes = JSON.parse(JSON.stringify(data));

      let productCodes: Array<string> = [];
      let productCodesUnitsTooltips: Array<string> = [];
      let productToolTip: string = '';

      jsonGetProductQuotes.forEach(product => {
        productToolTip = this.GetTooltipForProduct(product.importCode);
        productCodes.push(product.importCode);
        productCodesUnitsTooltips.push(product.importCode + ";" + product.unitPrice + ";" + productToolTip);
      });

      let index: number = 1;
      var unit: string = '';

      if (!isRefresh) {
        // Création des tableaux des produits
        productCodesUnitsTooltips.forEach(productCodeUnitTooltip => {
          let factory: ComponentFactory<ProductTableEuronextComponent> = this.resolver.resolveComponentFactory(ProductTableEuronextComponent);
          let product_table = this.productTablesEuronext_Placeholder.createComponent(factory);

          this.AllProductTablesEuronext_Placeholder.push(product_table);

          var productCodeAndUnitSplitted = productCodeUnitTooltip.split(';');
          var productCode = productCodeAndUnitSplitted[0];
          var productTooltip = productCodeAndUnitSplitted[2];
          var productName = GlobalFunctions.GetProductNameFromCode(productCode, jsonGetProductQuotes);

          unit = productCodeAndUnitSplitted[1];

          product_table.instance.index = index;
          product_table.instance.productCode = productCode;
          product_table.instance.productName = productName;
          product_table.instance.productTooltip = productTooltip;
          product_table.instance.unit = unit;

          index++;
        });
      }

      let allProductTablesPlaceHolders: Array<ComponentRef<ProductTableEuronextComponent>> = this.AllProductTablesEuronext_Placeholder;

      // Ajout des prix dans les tableaux des produits
      this.quotesService.GetLastPriceFutureEuronext(productCodes).subscribe((data) => {
        const jsonQuotesService = JSON.parse(JSON.stringify(data));

        let isLastRow: boolean = false;
        let isLastVisibleRow: boolean = false;
        let textExpiry: string = '';
        var index: number = 0;
        var currentProductCode: string = '';
        var lblVariationSignWidth = '';

        if (jsonQuotesService && jsonQuotesService.length > 0) {
          let displayTime: boolean = false;
          let dateTimeRefreshData: Date = Dates.GlobalFunctionsDates.GetMostRecentDate(jsonQuotesService);
          let dateTimeRefreshDataWithoutTime: Date = new Date(dateTimeRefreshData);
          const dateTimeTodayWithoutTime: Date = new Date();

          dateTimeTodayWithoutTime.setHours(0, 0, 0, 0);
          dateTimeRefreshDataWithoutTime.setHours(0, 0, 0, 0);

          if (dateTimeRefreshDataWithoutTime >= dateTimeTodayWithoutTime)
            displayTime = true;

          this.RefreshDateUpdate(dateTimeRefreshData, displayTime);

          jsonQuotesService.forEach(line => {
            if (line.codeProduct != currentProductCode)
              index = 0;

            let price = line.close.toFixed(2);

            if (!isRefresh) { // If initial data loading
              isLastRow = (index == data.length - 1);
              isLastVisibleRow = (index == 5);

              var variationSign: string = (line.variation < 0 ? "-" : (line.variation > 0 ? "+" : ""));
              lblVariationSignWidth = (variationSign == "") ? "0" : "12px";

              allProductTablesPlaceHolders.forEach(productTablePlaceHolder => {
                if (productTablePlaceHolder.instance.productCode == line.codeProduct)
                  productTablePlaceHolder.instance.data.push(line);
              });

              var dateExpiry: Date = new Date(line.realDateExpiry);
              var monthNumberExpiry: number = dateExpiry.getMonth() + 1;
              var yearExpiry: string = dateExpiry.getFullYear().toString().substring(2);

              textExpiry = GlobalFunctions.CapitalizeFirstLetter(Dates.GlobalFunctionsDates.GetMonthShortName(monthNumberExpiry)) + " " + yearExpiry;
            }
            else { // If data is beeing refreshed
              var valueToReachPrice: number;
              var valueToReachVariation: number

              if (this.isTestMode) { // If we are using test mode
                valueToReachPrice = GlobalFunctions.RandomNumberInRange(15000, 35000) / 100; // Pour tests
                valueToReachVariation = (GlobalFunctions.RandomNumberInRange(0, 1000) / 100) * GlobalFunctions.PositiveOrNegative(); // Pour tests
              }
              else { // If we are using normal mode
                valueToReachPrice = price;
                valueToReachVariation = parseFloat(line.variation.toString());//.replace("-", ""));
              }

              this.UpdateNumber("lblPriceFutureEuronext_" + line.codeProduct + "_" + line.codeExpriy, index, valueToReachPrice, false);
              this.UpdateNumber("lblVariationValueFutureEuronext_" + line.codeProduct + "_" + line.codeExpriy, index, valueToReachVariation, true);
            }

            // Nécessaire pour redimensionement de la hauteur du composant principal contenant les tableaux des prix des produits au chargement de la page
            if (isLastVisibleRow)
              setTimeout(this.ResizeComponentHeight, 10);

            currentProductCode = line.codeProduct;
            index++;
          });

          // Add data lines in each product table
          allProductTablesPlaceHolders.forEach(productTablePlaceHolder => {
            productTablePlaceHolder.instance.LoadData(isRefresh);
          });

          if (!isRefresh) {
            var top: number = $(".divQuotes").height() / 2;

            $("#loaderEuronextQuotes").css("top", top + "px");
            $(".divEuronextQuotes").animate({ opacity: 0.4 }, 500);
            $("#loaderEuronextQuotes").show();

            setTimeout(this.HideLoader, 1000);
          } 
        }

        // Ajout de la dernière ligne (ligne vide), pour chaque tableau de produit
        if (!isRefresh) {
          productCodes.forEach(productCode => {
            $("#table_product_price_" + productCode + " > tbody").append("<tr style='background-color: #F3F5F6;'><td colspan='4' style='border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;'></td></tr>");
          });
        }        
      });
    });
  }

  GetProductName(products, codeProduct: string) {
    let productName: string = '';

    products.forEach(prod => {
      if (prod.importCode == codeProduct) {
        productName = prod.nameProduct;
      }
    });

    return productName;
  }

  OpenDialogChartFutures(productCode: string, productName: string, codeExpiry: string = '', textExpiry: string = ''): void {
    GlobalParams.BYPASS_SPINNER = new HttpContextToken(() => false);

    const dialogRef = this.dialog.open(PriceChartFuturesComponent, {
      width: '1000px',
      position: {},
      panelClass: 'custom-dialog-container',
      disableClose: false
    });

    dialogRef.componentInstance.dialogRef = dialogRef;
    dialogRef.componentInstance.productCode = productCode;
    dialogRef.componentInstance.productName = productName;

    if (codeExpiry)
      dialogRef.componentInstance.codeExpiry = codeExpiry;

    if (textExpiry)
      dialogRef.componentInstance.textExpiry = textExpiry;

    dialogRef.componentInstance.pricesType = "Futures";
  }

  UpdateNumber(id, index: number, valueToReach: number, isVariation: boolean) {
    var elt = document.getElementById(id);
    var currentValue: number = Number(document.getElementById(id).innerText);
    var currentValueSign: string = document.getElementById(id.replace("Value", "Sign")).innerText;
    var endNbrDecimalPart: number = parseInt(currentValue.toString().split(".").pop());

    if (currentValueSign == "-")
      currentValue = -1 * currentValue;

    if (isVariation) {
      var bgColor: string = (valueToReach < 0 ? "E00000" : (valueToReach > 0 ? "00840D" : "00840D"));

      // Signe de la variation
      $("#" + id.replace("Value", "Sign")).text((valueToReach > 0) ? "+" : (valueToReach < 0) ? "-" : "");
      $("#" + id.replace("Value", "Sign")).css("background-color", "#" + bgColor);

      // Valeur de la variation
      $("#" + id).css("background-color", "#" + bgColor);
    }

    if (currentValue != valueToReach) {
      this.UpdateNumberRecursive_IntPart(currentValue, valueToReach, id, elt, endNbrDecimalPart, currentValue <= valueToReach, index, isVariation);
    }
  }

  UpdateNumberRecursive_IntPart(currentValue: number, valueToReach: number, id: string, elt, endNbrDecimalPart: number, increase: boolean, index: number, isVariation: boolean) {
    var finished: boolean = false;

    if (currentValue == valueToReach) {
      return;
    }

    if (increase) { // Si le prix augmente
      if (parseInt(currentValue.toString().split(".")[0]) <= valueToReach) {  // Si on doit encore augmenter le prix
        elt.innerText = currentValue.toFixed(2).replace("-", "");

        var that = this;
        setTimeout(function () {
          that.UpdateNumberRecursive_IntPart(currentValue + 1, valueToReach, id, elt, endNbrDecimalPart, increase, index, isVariation);
        }, this.animationSpeed);
      }
      else
        finished = true;
    }
    else { // Si le prix baisse
      if (currentValue >= valueToReach) { // Si on doit encore baisser le prix
        elt.innerText = currentValue.toFixed(2).replace("-", "");

        var that = this;
        setTimeout(function () {
          that.UpdateNumberRecursive_IntPart(currentValue - 1, valueToReach, id, elt, endNbrDecimalPart, increase, index, isVariation);
        }, this.animationSpeed);
      }
      else
        finished = true;
    }

    if (finished) { // Si on a terminé l'augmentation ou la baisse du prix pour la partie entière, on passe à la modification des décimales
      var decimalValue: number = parseInt(valueToReach.toFixed(2).toString().split(".")[1]);

      if (currentValue < 0)
        elt.innerText = elt.innerText.replace("-", "");

      if (isNaN(decimalValue))
        decimalValue = 0;

      this.UpdateNumber_DecimalPart(id, decimalValue, increase, index, isVariation);
    }
  }

  UpdateNumber_DecimalPart(id, decimalValue: number, increase: boolean, index: number, isVariation: boolean) {
    var elt = document.getElementById(id);
    var endNbrDecimalPart = decimalValue;

    this.UpdateNumberRecursive_DecimalPart(increase ? 0 : 99, endNbrDecimalPart, elt, increase, index, isVariation);
  }

  UpdateNumberRecursive_DecimalPart(i, endNbrDecimalPart: number, elt, increase: boolean, index: number, isVariation: boolean) {
    var finished: boolean = false;

    if (increase) {
      if (i <= endNbrDecimalPart && i <= 99) {
        var val: string;

        if (i < 10)
          val = "0" + i.toString();
        else
          val = i.toString();

        elt.innerText = parseInt(elt.innerText) + "." + val;

        var that = this;
        setTimeout(function () {
          that.UpdateNumberRecursive_DecimalPart(i + 1, endNbrDecimalPart, elt, increase, index, isVariation);
        }, this.animationSpeed);
      }
      else
        finished = true;
    }
    else {
      if (i >= endNbrDecimalPart && i >= 0) {
        var val: string;

        if (i < 10)
          val = "0" + i.toString();
        else
          val = i.toString();

        elt.innerText = parseInt(elt.innerText) + "." + val;

        var that = this;
        setTimeout(function () {
          that.UpdateNumberRecursive_DecimalPart(i - 1, endNbrDecimalPart, elt, increase, index, isVariation);
        }, this.animationSpeed);
      }
      else
        finished = true;
    }

    if (finished) {
      $("#lblVariationValue_" + index).text(parseFloat($("#lblVariationValue_" + index).text()).toFixed(2));
      $("#lblPrice_" + index).text(parseFloat($("#lblPrice_" + index).text()).toFixed(2));
    }
  }

  onImgMouseover($event): void {
    const box = document.getElementById('divTooltipFuturesEuronext');
    box.style.display = 'block';
  }

  onImgMouseout($event): void {
    const box = document.getElementById('divTooltipFuturesEuronext');
    box.style.display = 'none';
  }

  onChangeNbLines() {
    var classToUse: string = '';
    var nbLines = $("#dropDownNbLines_Euronext").val();

    // TODO : à voir
    if (nbLines == "Max")
      nbLines = 20;

    if (parseInt(nbLines) == 10)
      classToUse = "tdCellProductPriceHidden";
    else
      classToUse = "tdCellProductPriceVisible";

    document.querySelectorAll(".table_product_price_euronext_summary_body >product_price_line >tr >td").forEach((cell) => {
      var rowIndex: number = parseInt(cell.parentElement.id);

      if (rowIndex) {
        if (rowIndex > nbLines) {
          cell.classList.add('tdCellProductPriceHidden');
          cell.classList.remove('tdCellProductPriceVisible');
        }
        else {
          cell.classList.remove('tdCellProductPriceHidden');
          cell.classList.add('tdCellProductPriceVisible');
        }
      }
    });

    this.ResizeComponentHeight();
  }

  ResizeComponentHeight() {
    let tblMaxHeight: number = 0;

    document.querySelectorAll(".table_product_price_euronext_summary_body").forEach((tbl) => {
      const tblHeight: number = $(tbl).height();

      if (tblHeight > tblMaxHeight)
        tblMaxHeight = tblHeight;
    });

    // $(".divQuotesFutureEuronext").height(195 + tblMaxHeight + "px");
  }
}
