import { Component, Inject, Injectable, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as advisorData from '../../../assets/config/config.json'
import { UserService } from 'src/app/services/http/user.service';
import { ContactAdvisor } from 'src/model/ContactAdvisor';

@Component({
  selector: 'contactAdvisor',
  templateUrl: './contact_advisor.component.html',
  styleUrls: ['./contact_advisor.component.scss']
})

@Injectable()
export class ContactAdvisorComponent implements OnInit {
  advisorData: any = advisorData;
  advisorInformation: ContactAdvisor = new ContactAdvisor();
  planId: number = 1;
  @Input()
  page: any = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private userService: UserService) { }
  ngOnInit(): void {
    this.getIdProfil();
    this.showOfferDependingOnStatus();
  }

  getIdProfil() {
    this.userService.GetIdProfil().subscribe({
      next: (value) => {
        this.planId = value.idProfil
      }
    })
  }

  showOfferDependingOnStatus() {
    switch (this.planId) {
      case 1:
        this.advisorInformation = this.advisorData.advisorInformationAgritelFarmerPlus;
        break;
      case 2:
      case 3:
      case 4:
        this.advisorInformation = this.advisorData.advisorInformationAgritelFarmer;
        break;
    }
  }
}





