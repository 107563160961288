import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/http/user.service';
import { NotificationService } from 'src/app/shared/components/notifications/notification.service';

@Component({
  selector: 'app-market-panoramic',
  templateUrl: './market-panoramic.component.html',
  styleUrls: ['./market-panoramic.component.scss']
})
export class MarketPanoramicComponent implements OnInit {

  constructor(private userService: UserService, private notificationService: NotificationService) { }

  toBeShown: boolean = false;
  ngOnInit(): void {
    this.getUserIdProfil();
  }
  getUserIdProfil() {
    this.userService.GetIdProfil().subscribe({
      next: (value) => {
        switch (value.idProfil) {
          case 1:
            this.toBeShown = false;
            break;
          case 2:
          case 3:
          case 4:
            this.toBeShown = true;
            break;
        }
      },
      error: (err) => {
        if (err.status !== 401) {
          this.notificationService.ShowError("Erreur lors du chargement des informations.", "");
        }
      },
      complete: () => {
        console.info("information received");
      },
    })
  }
}
