<div class="chart-detail-container">
  <div class="chart-detail-title-header">
    <img src="assets/images/chart_analysis.png" width="30" />
    <label class="chart-detail-label"> Analyse chartiste</label>
  </div>
  <div class="chart-detail-content-container">
    <div class="chart-detail-article-name">
      {{title}}
    </div>
    <div class="chart-detail-text-container">
      <div class="chart-detail-text" [innerHTML]="text | safeHtml"></div>
    </div>
    <div class="chart-detail-image-container">
      <a (click)="openDialog()" >
        @if (chartImage!='') {
          <img id="imgChart" src="{{chartImage}}" alt="Image analyse chartiste" width="700" />
        }
      </a>
    </div>
  </div>
</div>