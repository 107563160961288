import { HttpContextToken } from '@angular/common/http';
import { Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, Injectable, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import * as $ from 'jquery/dist/jquery.min.js';
import { GlobalFunctions, GlobalParams } from '../../../../global';
import { Dates } from '../../../../tools/Date';
import { QuotesService } from '../../../services/http/quotes.service';
import { PriceChartFuturesComponent } from '../charts/price_chart/price_chart_futures/price_chart_futures.component';
import { ProductTableCMEComponent } from '../product_table/product_table_CME/product_table_CME.component';
import { QuotesComponent } from '../quotes_component.component';

@Component({
  selector: 'CME_quotes',
  templateUrl: './CME_quotes.component.html',
  styleUrls: ['./CME_quotes.component.scss']
})

@Injectable()
export class CMEQuotesComponent extends QuotesComponent implements OnInit {
  animationSpeed = 5;
  isTestMode: boolean = false;
  isDelayBeforeNewRefresh: boolean = false;

  constructor(public dialog: MatDialog, private resolver: ComponentFactoryResolver, private quotesService: QuotesService) {
    super();
  }

  @ViewChild('productTablesCME_Placeholder', { read: ViewContainerRef })
  public productTablesCME_Placeholder: ViewContainerRef;

  AllProductTablesCME_Placeholder: Array<ComponentRef<ProductTableCMEComponent>> = [];

  override ngOnInit(): void {
    this.tableTitle = "Futures - CME";
    this.showDropDownNbLines = true;
    this.showRefreshButton = true;
    this.tableType = "CME";
  }

  RefreshDateUpdate(dateTimeRefreshData: Date, showTime: boolean) {
    var dateWithoutTime: string = dateTimeRefreshData.toLocaleString("fr-FR").split(' ')[0];

    if (showTime) {
      let time: string = dateTimeRefreshData.toLocaleTimeString("fr-FR", { hour: '2-digit', minute: '2-digit' });
      this.dateLastUpdate = dateWithoutTime + " " + time;
    }
    else
      this.dateLastUpdate = dateWithoutTime;

    this.dateLastUpdate += " (GMT" + Dates.GlobalFunctionsDates.GetTimezoneOffset() + ")";
  }

  RefreshFuturesCME() {
    if (this.isDelayBeforeNewRefresh)
      return;

    this.isDelayBeforeNewRefresh = true;

    $("#btRefreshFuturesCME").attr("style", "height:20px;cursor:default;");
    $("#lbRefreshFuturesCME").attr("style", "cursor:default;margin-bottom:0 !important;padding-left:5px;padding-right:15px;color:#dddddd");

    setTimeout(() => {
      this.isDelayBeforeNewRefresh = false;
      $("#btRefreshFuturesCME").attr("style", "height:20px;cursor:pointer;");
      $("#lbRefreshFuturesCME").attr("style", "cursor:pointer;margin-bottom:0 !important;padding-left:5px;padding-right:15px;");
    }, 2000);

    this.LoadData(true);
  }

  GetTooltipForProduct(productCode: string) {
    let tooltip: string = '';

    switch (productCode) {
      case "BO":
        tooltip = "Contrat à terme \"Huile de soja CME\"</br></br>" +
          "Contrat de 60 000 pounds en cents$ / pound</br>" +
          "<a href=\"https://www.cmegroup.com/markets/agriculture/oilseeds/soybean-oil.contractSpecs.html\" style=\"color:blue\" target=\"_blank\">Lien vers l'ensemble des spécifications</a>";
        break;
      case "C":
        tooltip = "Contrat à terme \"Corn CME\"</br></br>" +
          "Contrat de 5000 boisseaux en Cents$ / boisseau</br>" +
          "<a href=\"https://www.cmegroup.com/markets/agriculture/grains/corn.contractSpecs.html\" style=\"color:blue\" target=\"_blank\">Lien vers l'ensemble des spécifications</a>";
        break;
      case "S":
        tooltip = "Contrat à terme \"SOYBEAN\"</br>Sous-jacent : \"Yellow soybean\"</br></br>" +
          "Contrat de 5000 boisseaux (~136 tonnes métriques) en Cents$ / boisseau</br>" +
          "<a href=\"https://www.cmegroup.com/markets/agriculture/oilseeds/soybean.contractSpecs.html\" style=\"color:blue\" target=\"_blank\">Lien vers l'ensemble des spécifications</a>";
        break;
      case "W":
        tooltip = "Contrat à terme \"CHICAGO SRW WHEAT\"</br>Sous-jacent : Soft Red Winter wheat</br></br>" +
          "Contrat de 5000 boisseaux (~136 tonnes métriques) en Cents$ / boisseau</br>" +
          "<a href=\"https://www.cmegroup.com/markets/agriculture/grains/wheat.contractSpecs.html\" style=\"color:blue\" target=\"_blank\">Lien vers l'ensemble des spécifications</a>";
        break;
      case "SM":
        tooltip = "Contrat à terme</br></br>" +
          "Contrat à terme \"Tourteaux de soja\"</br>" +
          "Contrat de 100 short tons</br>" +
          "<a href=\"https://www.cmegroup.com/markets/agriculture/oilseeds/soybean-meal.contractSpecs.html\" style=\"color:blue\" target=\"_blank\">Lien vers l'ensemble des spécifications</a>";
        break;
    }

    return tooltip;
  }

  HideLoader() {
    $(".divCMEQuotes").animate({ opacity: 1 }, 500);
    $("#loaderCMEQuotes").hide();
  }

  override LoadData(isRefresh: boolean) {
    GlobalParams.BYPASS_SPINNER = new HttpContextToken(() => true);
    let productCodesUnitsMultipiersTooltips: Array<string> = [];

    if (isRefresh) {
      var top: number = $(".divCMEQuotes").height() / 2;

      $("#loaderCMEQuotes").css("top", top + "px");
      $(".divCMEQuotes").animate({ opacity: 0.4 }, 500);
      $("#loaderCMEQuotes").show();

      setTimeout(this.HideLoader, 1000);
    }

    this.quotesService.GetProductQuotes(1).subscribe((data) => {
      const jsonGetProductQuotes = JSON.parse(JSON.stringify(data));

      let productCodes: Array<string> = [];

      let productToolTip: string = '';
      let productMultiplier: number;

      jsonGetProductQuotes.forEach(product => {
        productToolTip = this.GetTooltipForProduct(product.importCode);
        productMultiplier = product.multiplier;
        productCodes.push(product.importCode);
        productCodesUnitsMultipiersTooltips.push(product.importCode + ";" + product.unitPrice.trim() + ";" + productToolTip + ";" + productMultiplier);
      });

      let index: number = 1;
      var unit: string = '';
      var multiplier: number;

      if (!isRefresh) {
        // Creation of product tables
        productCodesUnitsMultipiersTooltips.forEach(productCodeUnitMultiplierTooltip => {
          let factory: ComponentFactory<ProductTableCMEComponent> = this.resolver.resolveComponentFactory(ProductTableCMEComponent);
          let product_table = this.productTablesCME_Placeholder.createComponent(factory);

          this.AllProductTablesCME_Placeholder.push(product_table);

          var productCodeAndUnitMultiplierSplitted = productCodeUnitMultiplierTooltip.split(';');
          var productCode = productCodeAndUnitMultiplierSplitted[0];
          var productTooltip = productCodeAndUnitMultiplierSplitted[2];
          var productName = GlobalFunctions.GetProductNameFromCode(productCode, jsonGetProductQuotes);

          unit = productCodeAndUnitMultiplierSplitted[1];
          multiplier = parseInt(productCodeAndUnitMultiplierSplitted[3]);

          product_table.instance.index = index;
          product_table.instance.productCode = productCode;
          product_table.instance.productName = productName;
          product_table.instance.productTooltip = productTooltip;
          product_table.instance.unit = unit;
          product_table.instance.multiplier = multiplier;

          index++;
        });
      }

      let allProductTablesPlaceHolders: Array<ComponentRef<ProductTableCMEComponent>> = this.AllProductTablesCME_Placeholder;

      // Adding prices to product tables
      this.quotesService.GetLastPriceFutureCME(productCodes).subscribe((data) => {
        const jsonQuotesService = JSON.parse(JSON.stringify(data));

        var isLastRow = false;
        var index: number = 0;
        var currentProductCode: string = '';
        var lblVariationSignWidth = '';
        var productMultiplier: number;

        if (jsonQuotesService && jsonQuotesService.length > 0) {
          let displayTime: boolean = false;
          let dateTimeRefreshData: Date = Dates.GlobalFunctionsDates.GetMostRecentDate(jsonQuotesService);
          let dateTimeRefreshDataWithoutTime: Date = new Date(dateTimeRefreshData);
          const dateTimeTodayWithoutTime: Date = new Date();

          dateTimeTodayWithoutTime.setHours(0, 0, 0, 0);
          dateTimeRefreshDataWithoutTime.setHours(0, 0, 0, 0);

          if (dateTimeRefreshDataWithoutTime >= dateTimeTodayWithoutTime)
            displayTime = true;

          this.RefreshDateUpdate(dateTimeRefreshData, displayTime);

          jsonQuotesService.forEach(line => {
            if (line.codeProduct != currentProductCode)
              index = 0;

            productMultiplier = GlobalFunctions.FindProductMultiplier(productCodesUnitsMultipiersTooltips, line.codeProduct);

            var price: number = (line.close * productMultiplier);
            var variation: number = (line.variation * productMultiplier);

            if (productMultiplier != 1) {
              line.close = price;
              line.variation = variation;
            }

            if (!isRefresh) { // If initial data loading
              isLastRow = (index == data.length - 1);

              var variationSign: string = (variation < 0 ? "-" : (variation > 0 ? "+" : ""));
              lblVariationSignWidth = (variationSign == "") ? "0" : "12px";

              allProductTablesPlaceHolders.forEach(productTablePlaceHolder => {
                if (productTablePlaceHolder.instance.productCode == line.codeProduct)
                  productTablePlaceHolder.instance.data.push(line);
              });
            }
            else { // If data is beeing refreshed
              var valueToReachPrice: number;
              var valueToReachVariation: number

              if (this.isTestMode) {
                valueToReachPrice = GlobalFunctions.RandomNumberInRange(15000, 35000) / 100; // Pour tests
                valueToReachVariation = (GlobalFunctions.RandomNumberInRange(0, 1000) / 100) * GlobalFunctions.PositiveOrNegative(); // Pour tests
              }
              else {
                valueToReachPrice = price;
                var valueToReachVariation: number = parseFloat(variation.toString());//.replace("-", ""));                
              }

              this.UpdateNumber("lblPriceFutureCME_" + line.codeProduct + "_" + line.codeExpriy, index, valueToReachPrice, false);
              this.UpdateNumber("lblVariationValueFutureCME_" + line.codeProduct + "_" + line.codeExpriy, index, valueToReachVariation, true);
            }

            currentProductCode = line.codeProduct;
            index++;
          });

          // Add data lines in each product table
          allProductTablesPlaceHolders.forEach(productTablePlaceHolder => {
            productTablePlaceHolder.instance.LoadData(isRefresh);
          });

          if (!isRefresh) {
            var top: number = $(".divCMEQuotes").height() / 2;

            //alert("$(.divCMEQuotes).height() : " + $(".divCMEQuotes").height() + " top : " + top);

            $("#loaderCMEQuotes").css("top", top + "px");
            $(".divCMEQuotes").animate({ opacity: 0.4 }, 500);
            $("#loaderCMEQuotes").show();

            setTimeout(this.HideLoader, 1000);
          }
        }

        // Adding the last row (empty row at the end of the table)
        if (!isRefresh) {
          productCodes.forEach(productCode => {
            $("#table_product_price_" + productCode + " > tbody").append("<tr style='background-color: #F3F5F6;'><td colspan='4' style='border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;'></td></tr>");
          });
        }
      });
    });
  }

  GetProductName(products, codeProduct: string) {
    let productName: string = '';

    products.forEach(prod => {
      if (prod.importCode == codeProduct) {
        productName = prod.nameProduct;
      }
    });

    return productName;
  }

  OpenDialogChartFutures(productCode: string, productName: string, codeExpiry: string = '', textExpiry: string = '', multiplier: number): void {
    GlobalParams.BYPASS_SPINNER = new HttpContextToken(() => false);
    const dialogConfig = new MatDialogConfig()

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "custom-dialog-container";
    console.log(dialogConfig.panelClass);

    const dialogRef = this.dialog.open(PriceChartFuturesComponent, dialogConfig);

  
    dialogRef.componentInstance.dialogRef = dialogRef;
    dialogRef.componentInstance.productCode = productCode;
    dialogRef.componentInstance.productName = productName;
    dialogRef.componentInstance.multiplier = multiplier;

    if (codeExpiry)
      dialogRef.componentInstance.codeExpiry = codeExpiry;

    if (textExpiry)
      dialogRef.componentInstance.textExpiry = textExpiry;

    dialogRef.componentInstance.pricesType = "Futures";
  }

  UpdateNumber(id, index: number, valueToReach: number, isVariation: boolean) {
    var elt = document.getElementById(id);
    var currentValue: number = Number(document.getElementById(id).innerText);
    var currentValueSign: string = document.getElementById(id.replace("Value", "Sign")).innerText;
    var endNbrDecimalPart: number = parseInt(currentValue.toString().split(".").pop());

    if (currentValueSign == "-")
      currentValue = -1 * currentValue;

    if (isVariation) {
      var bgColor: string = (valueToReach < 0 ? "E00000" : (valueToReach > 0 ? "00840D" : "00840D"));

      // Signe de la variation
      $("#" + id.replace("Value", "Sign")).text((valueToReach > 0) ? "+" : (valueToReach < 0) ? "-" : "");
      $("#" + id.replace("Value", "Sign")).css("background-color", "#" + bgColor);

      // Valeur de la variation
      $("#" + id).css("background-color", "#" + bgColor);
    }

    if (parseFloat(currentValue.toFixed(2)) != parseFloat(valueToReach.toFixed(2))) {
      this.UpdateNumberRecursive_IntPart(currentValue, valueToReach, id, elt, endNbrDecimalPart, currentValue <= valueToReach, index, isVariation);
    }
  }

  UpdateNumberRecursive_IntPart(currentValue: number, valueToReach: number, id: string, elt, endNbrDecimalPart: number, increase: boolean, index: number, isVariation: boolean) {
    var finished: boolean = false;

    if (currentValue == valueToReach) {
      return;
    }

    if (increase) { // Si le prix augmente
      if (parseInt(currentValue.toString().split(".")[0]) <= valueToReach) {  // Si on doit encore augmenter le prix
        elt.innerText = currentValue.toFixed(2).replace("-", "");

        var that = this;
        setTimeout(function () {
          that.UpdateNumberRecursive_IntPart(currentValue + 1, valueToReach, id, elt, endNbrDecimalPart, increase, index, isVariation);
        }, this.animationSpeed);
      }
      else
        finished = true;
    }
    else { // Si le prix baisse
      if (currentValue >= valueToReach) { // Si on doit encore baisser le prix
        elt.innerText = currentValue.toFixed(2).replace("-", "");

        var that = this;
        setTimeout(function () {
          that.UpdateNumberRecursive_IntPart(currentValue - 1, valueToReach, id, elt, endNbrDecimalPart, increase, index, isVariation);
        }, this.animationSpeed);
      }
      else
        finished = true;
    }

    if (finished) { // Si on a terminé l'augmentation ou la baisse du prix pour la partie entière, on passe à la modification des décimales
      var decimalValue: number = parseInt(valueToReach.toFixed(2).toString().split(".")[1]);

      if (currentValue < 0)
        elt.innerText = elt.innerText.replace("-", "");

      if (isNaN(decimalValue))
        decimalValue = 0;

      this.UpdateNumber_DecimalPart(id, decimalValue, increase, index, isVariation);
    }
  }

  UpdateNumber_DecimalPart(id, decimalValue: number, increase: boolean, index: number, isVariation: boolean) {
    var elt = document.getElementById(id);
    var endNbrDecimalPart = decimalValue;

    this.UpdateNumberRecursive_DecimalPart(increase ? 0 : 99, endNbrDecimalPart, elt, increase, index, isVariation);
  }

  UpdateNumberRecursive_DecimalPart(i, endNbrDecimalPart: number, elt, increase: boolean, index: number, isVariation: boolean) {
    var finished: boolean = false;

    if (increase) {
      if (i <= endNbrDecimalPart && i <= 99) {
        var val: string;

        if (i < 10)
          val = "0" + i.toString();
        else
          val = i.toString();

        elt.innerText = parseInt(elt.innerText) + "." + val;

        var that = this;
        setTimeout(function () {
          that.UpdateNumberRecursive_DecimalPart(i + 1, endNbrDecimalPart, elt, increase, index, isVariation);
        }, this.animationSpeed);
      }
      else
        finished = true;
    }
    else {
      if (i >= endNbrDecimalPart && i >= 0) {
        var val: string;

        if (i < 10)
          val = "0" + i.toString();
        else
          val = i.toString();

        elt.innerText = parseInt(elt.innerText) + "." + val;

        var that = this;
        setTimeout(function () {
          that.UpdateNumberRecursive_DecimalPart(i - 1, endNbrDecimalPart, elt, increase, index, isVariation);
        }, this.animationSpeed);
      }
      else
        finished = true;
    }

    if (finished) {
      $("#lblVariationValue_" + index).text(parseFloat($("#lblVariationValue_" + index).text()).toFixed(2));
      $("#lblPrice_" + index).text(parseFloat($("#lblPrice_" + index).text()).toFixed(2));
    }
  }

  onImgMouseover($event): void {
    const box = document.getElementById('divTooltipFuturesCME');
    box.style.display = 'block';
  }

  onImgMouseout($event): void {
    const box = document.getElementById('divTooltipFuturesCME');
    box.style.display = 'none';
  }

  onChangeNbLines() {
    var classToUse: string = '';
    var nbLines = $("#dropDownNbLines_CME").val();

    // TODO : à voir
    if (nbLines == "Max")
      nbLines = 20;

    if (parseInt(nbLines) == 10)
      classToUse = "tdCellProductPriceHidden";
    else
      classToUse = "tdCellProductPriceVisible";

    document.querySelectorAll(".table_product_price_CME_summary_body >product_price_line >tr >td").forEach((cell) => {
      var rowIndex: number = parseInt(cell.parentElement.id);

      if (rowIndex) {
        if (rowIndex > nbLines) {
          cell.classList.add('tdCellProductPriceHidden');
          cell.classList.remove('tdCellProductPriceVisible');
        }
        else {
          cell.classList.remove('tdCellProductPriceHidden');
          cell.classList.add('tdCellProductPriceVisible');
        }
      }
    });
  }
}
