import { Component, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-fertilizer',
  templateUrl: './fertilizer.component.html',
  styleUrls: ['./fertilizer.component.scss']
})
export class FertilizerComponent implements OnInit{
classBlurred: any;

  constructor(){}

  @Output() sentProductId : number = 9; 

  @Output() strategyTitle : string = 'Engrais'; 
  @Output() secondaryStrategyTitle : string = 'Engrais de fond'; 


  ngOnInit(): void {}


}
