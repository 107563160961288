import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { QuestionService } from '../services/http/question.service';
import { NotificationService } from '../shared/components/notifications/notification.service';

@Component({
  selector: 'app-form-minute-chrono',
  templateUrl: './form-minute-chrono.component.html',
  styleUrl: './form-minute-chrono.component.scss'
})
export class FormMinuteChronoComponent {

  constructor(private questionService: QuestionService, private notificationService: NotificationService) { }

  askForm = new FormGroup({
    question: new FormControl('', [Validators.required, Validators.maxLength(255)]),
  })


  onClick() {
    this.questionService.createQuestion(this.askForm.value.question!).subscribe({
      error: (err: { status: number; error: string | undefined; }) => {
        if (err.status != 401) {
          this.notificationService.ShowError(err.error, "")
        }
      },
      complete: () => {
        console.info("Send completed");
        this.notificationService.ShowSuccess("Votre question à bien été envoyer", "")
      }
    })
  }

}
